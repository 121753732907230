import React, { Component } from 'react'
import { Tooltip } from '@cimpress/react-components'
import { FETCH_CARRIER_SERVICES_FAILURE } from '../../../actions/capi'

export class ShippingCarrierService extends Component {
	render() {
		const resolved = this.props.carrierServices[this.props.value]
		if (resolved) {
			if (resolved.hasSimilarName) {
				return (
					<div>
						<span>{resolved.name}</span>{' '}
						<Tooltip
							direction={'top'}
							contents={
								'Carrier service with same name detected, please use provided ReCS key for further identification'
							}
						>
							<i className="fa fa-info-circle info" aria-hidden="true" />{' '}
							<code className="code">{this.props.value}</code>
						</Tooltip>
					</div>
				)
			}
			return resolved.name
		}
		if (
			this.props.errors &&
			this.props.errors.find(
				err => err.reason === FETCH_CARRIER_SERVICES_FAILURE
			)
		) {
			return this.props.value
		}

		return (
			<div style={{ position: 'relative' }}>
				<span key={this.props.value}>
					<Tooltip
						direction={'top'}
						contents={'Shipping carrier service could not be found in SAPI'}
					>
						<span>
							<i
								className="fa fa-warning"
								style={{ color: 'orange' }}
								aria-hidden="true"
							/>
							{this.props.value}
						</span>
					</Tooltip>
				</span>
			</div>
		)
	}
}
