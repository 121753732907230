import { sortManApiClient } from '../common/api/sortMan'
import { locations, isFetching } from '../selectors/locations'

export const FETCH_LOCATIONS_REQUEST = 'FETCH_LOCATIONS_REQUEST'
export const FETCH_LOCATIONS_SUCCESS = 'FETCH_LOCATIONS_SUCCESS'
export const FETCH_LOCATIONS_FAILURE = 'FETCH_LOCATIONS_FAILURE'

const requestLocations = () => ({
	type: FETCH_LOCATIONS_REQUEST
})
const receiveLocationsSuccess = locations => ({
	type: FETCH_LOCATIONS_SUCCESS,
	locations
})
const receiveLocationsFailure = (error) => ({
	type: FETCH_LOCATIONS_FAILURE,
	error: {
		...error,
		reason: FETCH_LOCATIONS_FAILURE
	}
})

const fetchLocations = async (dispatch, getState) => {
	const state = getState()
	if (isFetching(state)) {
		return null
	}

	dispatch(requestLocations())

	let response
	try {
		response = await sortManApiClient.get(
			'/api/v0/principal/fulfillment-locations'
		)
		dispatch(receiveLocationsSuccess(processLocations(response.data)))
	} catch (error) {
		dispatch(
			receiveLocationsFailure(error)
		)
	}
}

export const ensureLocationsAreFetched = () => (dispatch, getState) => {
	const state = getState()
	if (locations(state).length > 0) {
		return null
	}
	fetchLocations(dispatch, getState)
}

const processLocations = data =>
	Object.keys(data).reduce((carry, current) => {
		carry.push({
			fulfillmentLocationId: current,
			name: data[current]
		})
		return carry
	}, [])

export const UPDATE_CURRENT_LOCATION = 'UPDATE_CURRENT_LOCATION'
export const updateCurrentLocation = (locationId, locationInformation) => ({
	type: UPDATE_CURRENT_LOCATION,
	locationId,
	locationInformation
})

export const ERROR_GETTING_LOCATION = 'ERROR_GETTING_LOCATION'
export const errorGettingLocation = (locationId, error) => ({
	type: ERROR_GETTING_LOCATION,
	locationId,
	error: {
		...error,
		reason: ERROR_GETTING_LOCATION
	}
})
