import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { Select } from '@cimpress/react-components'
import {
	isFetching,
	locations,
	formatLocationsForSelect,
	isSuccessfullyLoaded
} from '../selectors/locations'
import Spinner from '@cimpress/react-components/lib/shapes/Spinner'

import { withRouter } from 'react-router'

class LocationSelector extends PureComponent {

	redirectToLocation() {
		const { locations, history, location } = this.props
		localStorage.setItem('location', location.value)
		history.push(`/rules/${locations[0].fulfillmentLocationId}`)
	}

	onLocationChange(history, location) {
		if (location && location.value) {
			localStorage.setItem('location', location.value)
			history.push(`/rules/${location.value}`)
		}
	}

	componentDidUpdate(prevProps) {
		if (this.props.locations.length === 1 && !prevProps.location.pathname.includes(this.props.locations[0].fulfillmentLocationId)) {
			this.redirectToLocation()
		}
	}

	render() {
		const { isFetching, locations, history, isSuccessfullyLoaded } = this.props

		if (isFetching || !isSuccessfullyLoaded) {
			return (
				<div>
					<h3 className="text-center">Loading available locations</h3>
					<Spinner size={48} className="text-center" />
				</div>
			)
		} else {
			if (!locations.length) {
				return (
					<div className="container-fluid container-vertical-stretch">
						<div className="row route-location">
							<div className="col-md-6 center-block">
								<h3 className="text-center">
									You don't have permission to any location configuration.
								</h3>
							</div>
						</div>
					</div>
				)
			} else if (locations.length > 1) {
				return (
					<div>
						<h3 className="text-center">Please select your location</h3>
						<div className="row">
							<div className="col-md-10">
								<Select
									placeholder="Select fulfillment location..."
									options={formatLocationsForSelect(locations)}
									onChange={this.onLocationChange.bind(this, history)}
									value={localStorage.getItem('location')}
									clearable={false}
								/>
							</div>
              <div className="col-md-1" style={{margin: "29px 0px"}}>
								<div className="form-group form-group-active">
									<button
										className="btn btn-outline-secondary ok"
										onClick={() => this.onLocationChange(history, {value: localStorage.getItem('location')})}>
										Ok
									</button>
								</div>
              </div>
						</div>
					</div>
				)
			}

			return null
		}
	}
}

export default withRouter(
	connect(state => ({
		isFetching: isFetching(state),
		locations: locations(state),
		isSuccessfullyLoaded: isSuccessfullyLoaded(state)
	}))(LocationSelector)
)
