import React, { Component } from 'react'
import { Condition } from './Condition'
import { SortInformation } from './SortInformation'
import { default as RowControls } from './Row/Controls'
import { ANY_EXPRESSION, formatExpression } from '../../common/helpers/rule'

import { Dimensions } from './Columns/Dimensions'
import { ShippingCarrierService } from './Columns/ShippingCarrierService'
import { Weight } from './Columns/Weight'
import { Country } from './Columns/Country'
import { PickupSchedule } from './Columns/PickupSchedule'
import { TariffCategory } from './Columns/TariffCategory'
import { SortType } from './Columns/SortType'
import { PostalCode } from './Columns/PostalCode'
import { ShippingBox } from './Columns/ShippingBox'
import { CarrierServiceOptions } from './Columns/CarrierServiceOptions'

export class Rule extends Component {
	render() {
		const displaySortInformation = (sortInformation, sortInformationKeys) => {
			return sortInformationKeys.map(key => {
				let sortInfo = sortInformation.find(info => info.data[key])
				let sortInfoDataValue = sortInfo && sortInfo.data[key]

				return (
					<td className="sort-info-wrapper" key={`sort-info-wrapper-${key}`}>
						<SortInformation
							sortInformation={sortInfoDataValue}
							key={key}
							valueSet={sortInfoDataValue !== undefined}
						/>
					</td>
				)
			})
		}

		const constructDisplayConditions = (conditionKeys, conditions) => {
			let displayConditions = []
			conditionKeys.forEach(conditionKey => {
				const conditionsByType = conditions.filter(
					condition => condition.type === conditionKey
				)
				if (conditionsByType.length < 1) {
					displayConditions.push({
						aggConditions: [{ expression: ANY_EXPRESSION }]
					})
				}
				conditionsByType.forEach(condition => {
					const matching = displayConditions.find(
						displayCondition => displayCondition.type === condition.type
					)
					if (matching) {
						matching.aggConditions.push(condition)
					} else {
						displayConditions.push({
							type: condition.type,
							aggConditions: [
								{
									value: condition.value,
									expression: condition.expression,
									type: condition.type
								}
							]
						})
					}
				})
			})
			displayConditions.forEach(displayCondition => {
				displayCondition.aggConditions.sort((aggCon1, aggCon2) => {
					if (aggCon1.expression < aggCon2.expression) return -1
					if (aggCon1.expression > aggCon2.expression) return 1
					return 0
				})
			})
			return displayConditions
		}
		const transformConditions = (
			conditions,
			conditionKeys,
			carrierServices,
			pickupSchedules,
			shippingBoxes
		) => {
			const aggColValue = (type, value) => {
				switch (type) {
					case 'weight':
						return <Weight value={value} />
					case 'dimensions':
						return <Dimensions value={value} />
					case 'shipping-carrier':
						return (
							<ShippingCarrierService
								value={value}
								carrierServices={carrierServices}
								errors={this.props.errors}
							/>
						)
					case 'country':
						return <Country value={value} />
					case 'pickupScheduleId':
						return (
							<PickupSchedule
								value={value}
								pickupSchedules={pickupSchedules}
								errors={this.props.errors}
							/>
						)
					case 'tariff-category':
						return (
							<TariffCategory value={value} />
						)
					case 'sortation-type':
						return (
							<SortType value={value}/>
						)
					case 'postal-code':
						return (
							<PostalCode value={value} />
						)
					case 'shipping-box':
						return(
							<ShippingBox
								value={value}
								shippingBoxes={shippingBoxes}
							/>
						)
					case 'carrier-service-options':
						const shippingCarrierCondition = this.props.rule.conditions.find(condition => condition.type === 'shipping-carrier')
						const selectedCarrierService = shippingCarrierCondition && shippingCarrierCondition.value
						return (
							<CarrierServiceOptions
								value={value}
								carrierServices={carrierServices}
								selectedCarrierService={selectedCarrierService}
							/>
						)
					default:
						return value
				}
			}

			const aggColExpression = condition => {
				switch (condition.expression) {
					case 'range':
						return (
							<span>
								{aggColValue(condition.type, condition.value.start)} and {aggColValue(condition.type, condition.value.end)}
							</span>
						)
					default:
						return aggColValue(condition.type, condition.value)
				}
			}

			return constructDisplayConditions(conditionKeys, conditions).map(
				displayCondition => {
					return displayCondition.aggConditions.map((aggCondition, index) => {
						const formattedExpression = formatExpression(
							aggCondition.expression
						)
						return (
							<span key={index} className="condition">
								{formattedExpression && (
									<span className="tag oval">{formattedExpression}</span>
								)}
								{aggColExpression(aggCondition)}
							</span>
						)
					})
				}
			)
		}

		const conditions = transformConditions(
			this.props.rule.conditions,
			this.props.conditionKeys,
			this.props.carrierServices,
			this.props.pickupSchedules,
			this.props.shippingBoxes
		)
		let stateClass = ''
		if (this.props.rule.isRemoved) {
			stateClass = 'removed'
		} else if (this.props.rule.isAdded) {
			stateClass = 'added'
		} else if (this.props.rule.isChanged) {
			stateClass = 'changed'
		}

		// Potentially in combination with other classes
		if (this.props.rule.isReordered) {
			stateClass += ' reordered'
		}
		return (
			<tr
				className={`draggable ${stateClass.trim()}`}
				key={this.props.rule.index}
			>
				<td>
					<i className="fa fa-bars fa-2 drag-handle" aria-hidden="true" />
				</td>
				{conditions.map((condition, index) => (
					<Condition
						condition={condition}
						key={index}
						index={index}
						keys={conditions}
					/>
				))}
				{ displaySortInformation(this.props.rule.sortInformation, this.props.sortInformationKeys) }
				<td className="controls">
					<RowControls
						rule={this.props.rule}
						removeRule={this.props.removeRule.bind(
							null,
							this.props.rule.index,
							this.props.ruleSetId
						)}
						editRule={this.props.editRule.bind(
							null,
							this.props.rule.index,
							this.props.ruleSetId
						)}
						revertRule={this.props.revertRule.bind(
							null,
							this.props.rule.index,
							this.props.ruleSetId
						)}
					/>
				</td>
			</tr>
		)
	}
}
