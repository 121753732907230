import React, { Component } from 'react'
import PropTypes from 'prop-types'

export class Navtabs extends Component {
	constructor(props) {
		super(props)
		this.onTabClick = this.onTabClick.bind(this)
		this.state = {
			tabIndex: 0
		}
	}

	onTabClick(tabIndex) {
		this.setState({
			tabIndex: tabIndex
		})
	}

	render() {
		const isDisabled = index => {
			if (
				!this.props.contentTabs.length ||
				!this.props.contentTabs[index].props
			) {
				return false
			}
			return this.props.contentTabs[index].props.isDisabled
		}

		return (
			<div>
				<ul className="nav nav-tabs">
					{this.props.navigationTabs.map((navigation, index) => {
						return (
							<li
								key={index}
								className={
									index === this.state.tabIndex
										? 'active'
										: isDisabled(index) ? 'disabled' : ''
								}
							>
								<a
									onClick={e => {
										if (e) {
											e.preventDefault()
										}
										this.onTabClick(index)
									}}
									href={`#tab-${index}`}
									data-toggle="tab"
								>
									{navigation.title}
								</a>
							</li>
						)
					})}
				</ul>
				<div className="tab-content">
					{this.props.contentTabs.map((content, index) => {
						return (
							<div
								key={index}
								className={`tab-pane fade in ${
									this.state.tabIndex === index ? 'active' : ''
								}`}
								id={`tab-${index}`}
							>
								{content}
							</div>
						)
					})}
				</div>
			</div>
		)
	}
}

Navtabs.propTypes = {
	contentTabs: PropTypes.array.isRequired,
	navigationTabs: PropTypes.array.isRequired
}
