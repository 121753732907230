import * as actions from '../actions/boxman'

export default (
	state = { shippingBoxes: [], isFetching: false },
	action
) => {
	switch (action.type) {
		case actions.FETCH_SHIPPING_BOXES_REQUEST:
			return { ...state, isFetching: true }
		case actions.FETCH_SHIPPING_BOXES_SUCCESS:
			return {
				...state,
				shippingBoxes: action.shippingBoxes,
				isFetching: false
			}
		case actions.FETCH_SHIPPING_BOXES_NO_CONFIG:
			return {
				...state,
				isFetching: false,
				shippingBoxes: []
			}
		case actions.FETCH_SHIPPING_BOXES_FAILURE:
				return {
					...state,
					isFetching: false,
					shippingBoxes: [],
					error: action.error
				}
		default:
			return state
	}
}