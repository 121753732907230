export const revertRule = (state, action) => {
	return {
		...state,
		ruleSets: state.ruleSets.map(ruleSet => {
			if (ruleSet.uuid !== action.uuid) {
				return ruleSet
			}
			const revertedRule = ruleSet.rules.find(
				rule => rule.index === action.index
			)
			if (revertedRule.isAdded) {
				// In case rule was added in current session we do not make any guesses about how to revert
				return ruleSet
			}

			// Original resources should always exist at this point (we already know rule was not added in current session)
			const originalRuleSet = state.originalRuleSets.find(
				ruleSet => ruleSet.uuid === action.uuid
			)
			const originalRule = originalRuleSet.rules.find(
				rule => rule.index === action.index
			)

			let rulesWithRevertedRule = []
			if (revertedRule.isReordered) {
				// Remove reverted rule from rule set
				rulesWithRevertedRule = ruleSet.rules
					.map(rule => {
						if (rule.index !== action.index) {
							return rule
						}
						return null
					})
					.filter(rule => rule)
				// Insert rule at original position
				rulesWithRevertedRule.splice(originalRule.index, 0, originalRule)
			} else {
				rulesWithRevertedRule = ruleSet.rules.map(rule => {
					if (rule.index !== action.index) {
						return rule
					}
					return originalRule
				})
			}

			return {
				...ruleSet,
				rules: rulesWithRevertedRule
			}
		})
	}
}

export const revertRuleSet = (state, action) => {
	return {
		...state,
		ruleSets: state.ruleSets.map(ruleSet => {
			if (ruleSet.uuid !== action.uuid) {
				return ruleSet
			}
			const originalRuleSet = state.originalRuleSets.find(
				ruleSet => ruleSet.uuid === action.uuid
			)
			if (originalRuleSet) {
				return originalRuleSet
			}
			return ruleSet
		})
	}
}
