import axios from 'axios'
import { provideJWT } from './token'

const createClient = (baseURL) => {
  const client = axios.create({baseURL})

  // Add a request interceptor
  client.interceptors.request.use(provideJWT)

  // Add a response interceptor
  client.interceptors.response.use(
      response => {
          return response
        },
      error => {
        if (error.response) {
            // Request was made but server responded with something other than 2xx
            let customError = {
                message: error.response.data, 
                statusCode: error.response.status
            }
            throw customError
        } 
        else {
            // Something else happened while setting up the request that triggered the error
            let customError = {
                message: error.message
            }
            throw customError
        }
      })

  return client
}

export { createClient }