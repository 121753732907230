import React from 'react'
import { AuthenticatedRoute } from './AuthenticatedRoute'
import LocationSelector from '../LocationSelector'
import './LocationAwareRoute.css'
import '../Container.css'
import { updateCurrentLocation, errorGettingLocation } from '../../actions/locations'
import { connect } from 'react-redux'
import { currentLocation } from '../../selectors/locations'
import { logisticsLocationClient } from '../../common/api/logisticsLocation'

class LocationAwareRoute extends AuthenticatedRoute {
	constructor() {
		super()
		this.getLocation = this.getLocation.bind(this)
		this.updateCurrentLocation = this.updateCurrentLocation.bind(this)
	}

	getLocation(props) {
		return props.computedMatch.params.location
	}

	async updateCurrentLocation(locationId) {
		if (!locationId){
			this.props.updateCurrentLocation(locationId, null)
		} else {
			let response
			try {
				response = await logisticsLocationClient.get(`/v0/locations?fulfillmentLocationId=${locationId}`)
				let locationInformation = {}
				if (response.data && response.data.items && response.data.items[0] && response.data.items[0].localeSettings) {
					if (response.data.items[0].localeSettings.weightUnits) {
						locationInformation.weightUnits = response.data.items[0].localeSettings.weightUnits
					}

					if (response.data.items[0].localeSettings.lengthUnits) {
						locationInformation.lengthUnits = response.data.items[0].localeSettings.lengthUnits
					}
				}

				if (Object.keys(locationInformation).length > 0) {
					this.props.updateCurrentLocation(locationId, locationInformation)
				} else {
					this.props.errorGettingLocation(locationId, 'No Locale Settings')
				}
			} catch (err) {
				this.props.errorGettingLocation(locationId, err)
			}
		}
	}

	componentDidUpdate(prevProps) {
		if (this.getLocation(prevProps) !== this.getLocation(this.props)) {
			this.updateCurrentLocation(this.getLocation(this.props))
		}
	}

	componentDidMount() {
		this.updateCurrentLocation(this.getLocation(this.props))
	}

	render() {
		if (this.props.currentLocation) {
			// dispatch
			return React.createElement(this.props.component, {
				locationId: this.props.currentLocation.fulfillmentLocationId
			})
		}
		return (
			<div className="container-fluid container-vertical-stretch">
				<div className="row route-location">
					<div className="col-md-6 center-block">
						<LocationSelector />
					</div>
				</div>
			</div>
		)
	}
}
export default connect(
	(state, props) => ({ currentLocation: currentLocation(state) }),
	{ updateCurrentLocation, errorGettingLocation }
)(LocationAwareRoute)
