import React, { Component } from 'react'
import { Select } from '@cimpress/react-components'

export const expressionOptions = [
	{ value: 'exists', label: 'Exists' }
]

export class CarrierServiceOptions extends Component {
	render() {
    const options = []

		const capabilities = this.props.selectedCarrierService && this.props.carrierServices[this.props.selectedCarrierService].capabilities

    capabilities && capabilities.forEach(capability => {
      if(capability.type === 'on-demand') {
        options.push({value: capability.key, label: capability.name})
      }
    })

		let placeholder

		if(!this.props.selectedCarrierService) placeholder = 'No Carrier Service Selected'
		else if(!capabilities) placeholder = 'This Carrier does not support any sortable Carrier Service Options'

		return (
			<Select
				name="y"
				value={this.props.value}
				options={options}
				onChange={this.props.changeConditionValue}
				id={`sort-info-text-${this.props.index}`}
				placeholder={placeholder}
			/>
		)
	}
}
