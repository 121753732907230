import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { Route } from 'react-router-dom'
import auth from '../../common/auth/auth'

export class AuthenticatedRoute extends PureComponent {
	componentDidMount() {
		if (!auth.isLoggedIn()) {
			auth.login()
		}
	}
	render() {
		return <Route {...this.props} />
	}
}

export default connect(state => ({
	auth: state.auth
}))(AuthenticatedRoute)
