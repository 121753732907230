import { sortStrategies } from '../selectors/selectors'
import { sortManApiClient } from '../common/api/sortMan'

export const FETCH_SORT_STRATEGIES_REQUEST = 'FETCH_SORT_STRATEGIES_REQUEST'
export const FETCH_SORT_STRATEGIES_SUCCESS = 'FETCH_SORT_STRATEGIES_SUCCESS'
export const FETCH_SORT_STRATEGIES_FAILURE = 'FETCH_SORT_STRATEGIES_FAILURE'

const requestSortStrategies = () => ({
	type: FETCH_SORT_STRATEGIES_REQUEST
})

const receiveSortStrategies = sortStrategies => ({
	type: FETCH_SORT_STRATEGIES_SUCCESS,
	sortStrategies
})

const failReceiveSortStrategies = (error) => ({
	type: FETCH_SORT_STRATEGIES_FAILURE,
	error: {
		...error,
		reason: FETCH_SORT_STRATEGIES_FAILURE
	}
})

const fetchSortStrategies = locationId => async (dispatch, getState) => {
	const state = getState()

	if (sortStrategies.isFetching(state)) {
		return null
	}
	dispatch(requestSortStrategies())

	let response
	try {
		response = await sortManApiClient.get(
			`/api/v0/fulfillment-locations/${locationId}/sort-strategies`
		)
		if (response && response.data) {
			dispatch(receiveSortStrategies(response.data))
		} else {
			throw new Error('Empty response while fetching sort strategies')
		}
	} catch (error) {
		dispatch(
			failReceiveSortStrategies(error)
		)
	}
}

export { fetchSortStrategies }
