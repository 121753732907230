import React, { Component, createRef } from 'react'
import { connect } from 'react-redux'
import Spinner from '@cimpress/react-components/lib/shapes/Spinner'

import {
	errors,
	pickupSchedules,
	capi,
	ruleSets,
	boxman
} from '../../selectors/selectors'

import {
	addCondition,
	addRule,
	addRuleSet,
	addSortInfo,
	cancelEditingRule,
	changeCondition,
	changeRuleSetConfiguration,
	closeSaveModalConfirmationDialog,
	confirmEditingRule,
	confirmRuleSetConfiguration,
	discardRuleSetConfiguration,
	editRule,
	fetchRuleSets,
	filterRules,
	openSaveModalConfirmationDialog,
	removeCondition,
	removeRule,
	removeSortInfo,
	reorderRules,
	revertRule,
	saveRuleSet,
	saveRuleSetSaved,
	revertRuleSet,
	sortInfoChange,
	changeDescription
} from '../../actions/ruleSets'

import { fetchCarrierServices } from '../../actions/capi'
import { fetchShippingBoxes } from '../../actions/boxman'
import {
	showRuleSetHistory,
	fetchRuleSetHistory
} from '../../actions/ruleSetHistory'
import { fetchSortStrategies } from '../../actions/sortStrategies'
import { fetchPickupSchedules } from '../../actions/pickupSchedule'

import Table from './Table'
import History from './History'
import EditRule from './EditRule'
import Settings from './Settings'
import LocationNotConfigured from './LocationNotConfigured'
import { ErrorNotify } from './ErrorNotify'
import EditNotify from './EditNotify'

import '../Container.css'
import auth from '../../common/auth/auth'

export class TableContainer extends Component {
	constructor(props) {
		super(props)
		this.scrollDiv = createRef()
	}
	componentDidMount() {
		if (this.props.isLoggedIn) {
			this.fetchRemoteData()
		}
	}

	componentDidUpdate(prevProps) {
		if (prevProps.isLoggedIn !== this.props.isLoggedIn) {
			this.fetchRemoteData()
		}
	}

	fetchRemoteData = () => {
		this.props.fetchSortStrategies(this.props.locationId)

		this.props.fetchRuleSets(this.props.locationId)
		// TODO: SAPI location as dependency
		this.props.fetchCarrierServices()
		this.props.fetchPickupSchedules()
		this.props.fetchShippingBoxes(this.props.locationId)
	}

	render() {
		return (
			<div className="container-fluid container-vertical-stretch">
				{this.props.isFetchingRuleSets ? (
					<Spinner className="text-center" size={36} />
				) : (
					<div>
						<ErrorNotify errors={this.props.errors} />
						<Settings
							editedRuleSetConfiguration={this.props.editedRuleSetConfiguration}
							onChange={this.props.changeRuleSetConfiguration}
							onConfirm={this.props.confirmRuleSetConfiguration}
							onDiscard={this.props.discardRuleSetConfiguration}
						/>
						{this.props.ruleSets.length ? (
							<div>
								<EditNotify ruleSets={this.props.ruleSets} />
								<History
									showRuleSetHistory={this.props.showRuleSetHistory}
									locationId={this.props.locationId}
								/>
								<EditRule
									addCondition={this.props.addCondition}
									cancelEditingRule={this.props.cancelEditingRule}
									confirmEditingRule={this.props.confirmEditingRule}
									scrollToBottomRule={this.scrollDiv}
									changeCondition={this.props.changeCondition}
									editedRule={this.props.editedRule}
									sortStrategies={this.props.sortStrategies}
									sortInfoChange={this.props.sortInfoChange}
									addSortInfo={this.props.addSortInfo}
									removeSortInfo={this.props.removeSortInfo}
									removeCondition={this.props.removeCondition}
									carrierServices={this.props.carrierServices}
									shippingBoxes={this.props.shippingBoxes}
								/>
								<Table
									ruleSets={this.props.ruleSets}
									sortStrategies={this.props.sortStrategies}
									shippingBoxes={this.props.shippingBoxes}
									pickupSchedules={this.props.pickupSchedules}
									carrierServices={this.props.carrierServices}
									viewRuleSet={this.props.viewRuleSet}
									openSaveModal={this.props.openSaveModal}
									editRule={this.props.editRule}
									removeRule={this.props.removeRule}
									revertRule={this.props.revertRule}
									revertRuleSet={this.props.revertRuleSet}
									reorderRules={this.props.reorderRules}
									saveRuleSet={this.props.saveRuleSet}
									filterRules={this.props.filterRules}
									openSaveModalConfirmationDialog={
										this.props.openSaveModalConfirmationDialog
									}
									closeSaveModalConfirmationDialog={
										this.props.closeSaveModalConfirmationDialog
									}
									searchText={this.props.searchText}
									saveRuleSetSaved={this.props.saveRuleSetSaved}
									addRule={this.props.addRule}
									changeRuleSetConfiguration={
										this.props.changeRuleSetConfiguration
									}
									showRuleSetHistory={this.props.showRuleSetHistory}
									fetchRuleSetHistory={this.props.fetchRuleSetHistory}
									errors={this.props.errors}
									changeDescription={this.props.changeDescription}
									descriptions={this.props.descriptions}
									locationId={this.props.locationId}
								/>
							</div>
						) : (
							<LocationNotConfigured
								onAdd={this.props.changeRuleSetConfiguration}
								locationId={this.props.locationId}
							/>
						)}
					</div>
				)}
				<div ref={this.scrollDiv}></div>
			</div>
		)
	}
}

export default connect(
	(state, props) => ({
		ruleSets: ruleSets.getRuleSets(state),
		carrierServices: capi.carrierServices(state),
		shippingBoxes: boxman.shippingBoxes(state),
		pickupSchedules: pickupSchedules.pickupSchedules(state),
		sortStrategies: state.sortStrategies,
		isFetchingRuleSets: ruleSets.isFetching(state),
		openSaveModal: state.ruleSets.openSaveModal,
		editedRule: ruleSets.getEditedRule(state),
		editedRuleSetConfiguration: ruleSets.getEditedRuleSetConfiguration(state),
		searchText: ruleSets.searchText(state),
		errors: errors.collectFrom(state),
		descriptions: ruleSets.descriptions(state),
		locationId: props.locationId,
		isLoggedIn: auth.isLoggedIn(),
	}),
	{
		addCondition,
		addSortInfo,
		cancelEditingRule,
		confirmEditingRule,
		changeCondition,
		addRule,
		addRuleSet,
		editRule,
		fetchRuleSets,
		fetchCarrierServices,
		fetchSortStrategies,
		fetchPickupSchedules,
		filterRules,
		removeSortInfo,
		removeCondition,
		removeRule,
		reorderRules,
		sortInfoChange,
		saveRuleSet,
		openSaveModalConfirmationDialog,
		closeSaveModalConfirmationDialog,
		saveRuleSetSaved,
		changeRuleSetConfiguration,
		showRuleSetHistory,
		fetchRuleSetHistory,
		confirmRuleSetConfiguration,
		discardRuleSetConfiguration,
		revertRule,
		revertRuleSet,
		changeDescription,
		fetchShippingBoxes
	}
)(TableContainer)
