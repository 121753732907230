import React, { Component } from 'react'
import { TextField } from '@cimpress/react-components'

export class SortInformationRow extends Component {
	constructor(props) {
		super(props)
		this.removeSortInfo = this.removeSortInfo.bind(this)
		this.onSortInfoChange = this.onSortInfoChange.bind(this)

		this.state = {
			initialData: this.props.piece.data
		}
	}

	removeSortInfo(key, sortStrategyId) {
		return () => {
			this.props.removeSortInfo(key, sortStrategyId)
		}
	}

	onSortInfoChange(sortStrategyId, sortInfoKey, option) {
		this.props.sortInfoChange(
			sortStrategyId,
			sortInfoKey,
			option ? option.value : null
		)
	}

	render() {
		const humanReadableSortAttribute = key => {
			let strategyWithAttribute = this.props.sortStrategies.strategies
				.find(strategy => strategy.attributes.find(attr => attr.name === key))

			if (strategyWithAttribute) {
				return strategyWithAttribute.attributes.find(attr => attr.name === key).title || key
			}

			return key
		}


		const getSortInfoChangeHandler = (sortStrategyId, sortInfoKey) => event =>
			this.onSortInfoChange(sortStrategyId, sortInfoKey, event.target)

		const getSortInformationValue = (sortStrategyId, sortInfoKey) => {
			return this.props.piece.data[sortInfoKey]
		}

		const getAttribute = key => {
			return this.props.sortStrategies.strategies
				.find(sortStrategy => this.props.piece.sortStrategyId === sortStrategy.uuid).attributes
				.find(attr => attr.name === key)
		}

		const changesAllowed = key => {
			return !this.state.initialData[key] || getAttribute(key).editableAfterCreation
		}

		const maxLength = key => {
			return getAttribute(key).maxLength
		}

		const getLabel = (key, sortInfo) => {
			if (sortInfo === '' || sortInfo === undefined) {
				return 'Please enter a value for this field.'
			}

			let maximumLength = maxLength(key)

			if (maximumLength && sortInfo.length > maximumLength) {
				return `Maximum allowed length of this field is ${maximumLength} characters.`
			}

			return ''
		}

		return Object.keys(this.props.piece.data).map((key, index) => {
		  const sortInfo = getSortInformationValue(this.props.piece.sortStrategyId, key)
      return (
        <div className="row sort-info" key={`sort-info-row-${index}`}>
          <div className="col-md-11">
            {humanReadableSortAttribute(key)}
            <TextField
              // validate and set classes/ error formatting if empty
              label={getLabel(key, sortInfo)}
              bsStyle={getLabel(key, sortInfo) ? 'warning' : ''}
              value={sortInfo}
              onChange={getSortInfoChangeHandler(
                this.props.piece.sortStrategyId,
                key
              )}
              disabled={!changesAllowed(key)}
              id={`sort-info-text-${index}`}
              title={!changesAllowed(key) ? 'This field cannot be changed after the rule is created.  To change, add a new rule with the appropriate conditions and then delete this rule.' : ''}
            />
          </div>
          <div className="col-md-1 text-right" hidden={!changesAllowed(key)}>
            <button
              className="btn btn-outline-secondary delete"
              onClick={this.removeSortInfo(key, this.props.piece.sortStrategyId)}
            >
              <i className="fa fa-trash" aria-hidden="true" />
            </button>
          </div>
        </div>
      )
    })
	}
}
