import * as actions from '../actions/sortStrategies'

export default (state = { strategies: [], isFetching: false }, action) => {
	switch (action.type) {
		case actions.FETCH_SORT_STRATEGIES_REQUEST:
			return { ...state, isFetching: true }
		case actions.FETCH_SORT_STRATEGIES_SUCCESS:
			return {
				...state,
				strategies: action.sortStrategies,
				isFetching: false
			}
		case actions.FETCH_SORT_STRATEGIES_FAILURE:
			return {
				...state,
				strategies: [],
				error: action.error,
				isFetching: false
			}
		default:
			return state
	}
}
