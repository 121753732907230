export const getSAPI = () => ({
	baseURL: process.env.REACT_APP_SAPI_BASE_URL
})

export const getSortMan = () => ({
	baseURL: process.env.REACT_APP_SORTMAN_BASEURL,
	redirectURL: process.env.REACT_APP_REDIRECT_URL,
	disableSaving: process.env.REACT_APP_DISABLE_SAVING === 'true'
})

export const getEnv = () =>
	(process.env.REACT_APP_ENV || '').toUpperCase() || 'DEV'

export const getAuth0 = () => ({
	clientId: 'flRilPEnB46Y06F4bmo3OJ8JosPzbpmr'
})

export const getLL = () =>({
	baseURL: process.env.REACT_APP_LL_BASE_URL
})
