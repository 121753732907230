import React, { Component } from 'react'
import { TextField } from '@cimpress/react-components'
import { connect } from 'react-redux'
import { locationInformation } from '../../selectors/locations'

export const expressionOptions = [
	{ value: 'lt', label: '<' },
	{ value: 'lte', label: '<=' },
	{ value: 'gt', label: '>' },
	{ value: 'gte', label: '>=' }
]

export class Dimensions extends Component {
	constructor(props) {
		super(props)
		this.changeHandler = this.changeHandler.bind(this)
	}

	changeHandler(value, dim, unit) {
		return e => {
			let newValue = {}
			if (Array.isArray(value)){
				newValue.x = value[0]
				newValue.y = value[1]
				newValue.z = value[2]
			} else {
				newValue = {...value}
			}

			newValue[dim] = parseFloat(e.target.value)
			newValue.unit = unit
			this.props.changeConditionValue({ value: newValue })
		}
	}

	render() {
		let unit
		let dimensions

		if(Array.isArray(this.props.value)) {
			unit = 'cm'
			dimensions = this.props.value
		} else {
			let locationUnit = 'cm'
			if (this.props.locationInformation && this.props.locationInformation.lengthUnits) {
				locationUnit = this.props.locationInformation.lengthUnits
			}

			unit = this.props.value.unit || locationUnit
			dimensions = [this.props.value.x, this.props.value.y, this.props.value.z]
		}

		return (
			<div className="row">
				{['x', 'y', 'z'].map((dim, i) => (
					<div className="col-md-4" key={i}>
						<TextField
							name="number"
							label={`${dim} (${unit})`}
							type="number"
							value={dimensions[i] || ''}
							onChange={this.changeHandler(this.props.value, dim, unit)}
							id={`dimensions-condition-value-${this.props.index}`}
						/>
					</div>
				))}
			</div>
		)
	}
}

export default connect(
	(state) => { return { locationInformation: locationInformation(state) }}
)(Dimensions)
