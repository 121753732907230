/* polyfills.js */

import 'core-js/fn/set'
import 'core-js/fn/array/find'
import 'core-js/fn/array/from'
import 'core-js/fn/array/includes'
import 'core-js/fn/number/is-nan'

import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { Footer } from '@cimpress/react-components'
import Header from '@cimpress-technology/react-platform-header/lib/Header'
import { Route, Redirect, Switch, NavLink } from 'react-router-dom'
import { withRouter } from 'react-router'
import FourOhFour from './404'
import RuleSetsTableContainer from './RuleSets/TableContainer'
import AuthenticatedRoute from './Routes/AuthenticatedRoute'
import LocationAwareRoute from './Routes/LocationAwareRoute'
import Contact from './Contact'
import { ensureLocationsAreFetched } from '../actions/locations'
import { currentLocation } from '../selectors/locations'
import { logInAction, logOutAction } from '../actions/auth'

import './App.css'

class App extends PureComponent {
  componentDidMount() {
    if (!this.props.isAuthenticated) {
      this.props.logInAction().then(() => {
        this.props.ensureLocationsAreFetched()
      })
    } else {
      this.props.ensureLocationsAreFetched()
    }
  }

	render() {
		return (
			<div>
				<Header
					responsive
					appTitle={
						<NavLink to="/">
							Sortation Manager{' '}
							{this.props.currentLocation
								? `- ${this.props.currentLocation.name}`
								: ''}
						</NavLink>
					}
					appLinks={[
						<NavLink to="/rules">Rules</NavLink>,
						<NavLink to="/contact">Contact</NavLink>
					]}
					profile={this.props.profile}
					isLoggedIn={this.props.isAuthenticated}
					onLogInClicked={this.props.logInAction}
					onLogOutClicked={this.props.logOutAction}
				/>
				<Switch>
					<AuthenticatedRoute
						exact
						path="/"
						render={() => <Redirect to="/rules" />}
					/>
					<Redirect path="/rules-table" to="/rules" />
					<LocationAwareRoute
						exact
						path="/rules/:location?"
						component={RuleSetsTableContainer}
					/>
					<Route path="/contact" component={Contact} />
					<Route path="*" component={FourOhFour} />
				</Switch>
				<Footer
					links={
						<ul>
							<a href="/contact">Contact Us</a>
						</ul>
					}
				/>
			</div>
		)
	}
}

export default withRouter(
	connect((state) => ({
			currentLocation: currentLocation(state),
			isAuthenticated: state.auth.isAuthenticated,
			profile: state.auth.profile
	}),
	{
		logInAction,
		logOutAction,
		ensureLocationsAreFetched
	})(App)
)
