import React, { Component } from 'react'
import { Modal, TextField } from '@cimpress/react-components'
import PropTypes from 'prop-types'

const MODES = {
	edit: {
		title: 'Change rule set settings',
		cancelButtonLabel: 'Discard changes',
		submitButtonLabel: 'Confirm changes',
		submitButtonIcon: 'fa-save'
	},
	create: {
		title: 'Create new rule set',
		cancelButtonLabel: 'Close without saving',
		submitButtonLabel: 'Configure rules',
		submitButtonIcon: 'fa-arrow-right'
	}
}

export default class Settings extends Component {
	constructor() {
		super()
		this.handleNameChange = this.handleNameChange.bind(this)
	}

	handleNameChange(e) {
		this.props.onChange(this.props.editedRuleSetConfiguration.ruleSetId, {
			name: e.target.value
		})
	}

	render() {
		// editedRuleSetConfiguration is null when no rule set is being edited
		const { data, ruleSetId } = this.props.editedRuleSetConfiguration || {}
		const { name } = data || {}
		const mode = ruleSetId ? 'edit' : 'create'
		const {
			title,
			cancelButtonLabel,
			submitButtonLabel,
			submitButtonIcon
		} = MODES[mode]

		return (
			<Modal
				show={this.props.editedRuleSetConfiguration !== null}
				title={title}
				footer={
					<div className="row">
						<div className="col-md-3 pull-left">
							<button
								className="btn btn-outline-secondary discard pull-left"
								onClick={this.props.onDiscard.bind(null, ruleSetId)}
							>
								<i className="fa fa-ban" aria-hidden="true" />
								{cancelButtonLabel}
							</button>
						</div>
						<div className="col-md-3 pull-right">
							<button
								className="btn btn-primary text-right confirm"
								onClick={this.props.onConfirm.bind(null, ruleSetId)}
							>
								<i className={submitButtonIcon + ' fa'} aria-hidden="true" />
								{submitButtonLabel}
							</button>
						</div>
					</div>
				}
				closeButton={true}
				bsSize="lg"
				onRequestHide={this.props.onDiscard.bind(null, ruleSetId)}
			>
				<div className="row">
					<div className="col-md-12">
						<TextField
							name="name"
							label="Rule set name"
							value={name}
							onChange={this.handleNameChange}
							required
						/>
					</div>
				</div>
			</Modal>
		)
	}
}

Settings.propTypes = {
	editedRuleSetConfiguration: PropTypes.object,
	onChange: PropTypes.func.isRequired,
	onConfirm: PropTypes.func.isRequired,
	onDiscard: PropTypes.func.isRequired
}
