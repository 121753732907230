import React, { Component } from 'react'
import { allExpressionOptions, Expression } from './Expression'
import './Condition.css'
import { Select } from '@cimpress/react-components'

export class Condition extends Component {
	constructor(props) {
		super(props)
		this.removeCondition = this.removeCondition.bind(this)
		this.changeConditionType = this.changeConditionType.bind(this)
	}

	removeCondition() {
		this.props.removeCondition(this.props.index)
	}

	changeConditionType(selected) {
		if (!selected) {
			return
		}
		this.props.changeCondition(this.props.index, selected.value, 'type')

		// Reset to default expression
		const selectedExpressionOptions = allExpressionOptions[selected.value]
		this.props.changeCondition(this.props.index, selectedExpressionOptions[0].value, 'expression')
	}

	render() {
	

		return (
			<div className="row condition">
				<div className="col-md-11">
					<div className="row">
						<div className="col-md-4">
							<Select
								name="x"
								value={this.props.condition.type}
								options={this.props.allowedConditionTypes}
								onChange={this.changeConditionType}
								clearable={false}
							/>
						</div>
						<div className="col-md-8">
							<Expression
								changeCondition={this.props.changeCondition}
								index={this.props.index}
								condition={this.props.condition}
								carrierServices={this.props.carrierServices}
								shippingBoxes={this.props.shippingBoxes}
								selectedCarrierService={this.props.selectedCarrierService}
							/>
						</div>
					</div>
				</div>
				<div className="col-md-1">
					<button
						className="btn btn-outline-secondary delete"
						onClick={this.removeCondition}
					>
						<i className="fa fa-trash" aria-hidden="true" />
					</button>
				</div>
			</div>
		)
	}
}
