import React, { Component } from 'react'
import { TextField } from '@cimpress/react-components'
import { connect } from 'react-redux'
import { locationInformation } from '../../selectors/locations'

export const expressionOptions = [
	{ value: 'eq', label: '=' },
	{ value: 'lt', label: '<' },
	{ value: 'lte', label: '<=' },
	{ value: 'gt', label: '>' },
	{ value: 'gte', label: '>=' }
]

export class Weight extends Component {
	constructor(props) {
		super(props)
		this.changeHandler = this.changeHandler.bind(this)
	}

	changeHandler(unit) {
		return e => {
			let newValue = {}
			newValue.number = e.target.value
			newValue.unit = unit
			this.props.changeConditionValue({ value: newValue })
		}
	}

	render() {
		let unit
		let value

		if (typeof this.props.value === 'number') {
			unit = 'kg'
			value = this.props.value
		} else {
			let locationUnit = 'kg'
			if (this.props.locationInformation && this.props.locationInformation.weightUnits) {
				locationUnit = this.props.locationInformation.weightUnits
			}
			unit = this.props.value.unit || locationUnit
			value = this.props.value.number || this.props.value
		}

		return (
			<TextField
				name="number"
				label={`weight (${unit})`}
				type="number"
				value={value}
				onChange={this.changeHandler(unit)}
				id={`weight-condition-value-${this.props.index}`}
			/>
		)
	}
}

export default connect(
	(state) => { return { locationInformation: locationInformation(state) }}
)(Weight)
