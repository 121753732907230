import { ruleSets } from '../selectors/selectors'
import { sortManApiClient } from '../common/api/sortMan'
import { normalizeForBackend } from '../normalizers/ruleSets'

export const ADD_CONDITION = 'ADD_CONDITION'
export const addCondition = (ruleIndex, ruleSetId) => ({
	type: ADD_CONDITION,
	ruleSetId,
	ruleIndex
})

export const REMOVE_CONDITION = 'REMOVE_CONDITION'
export const removeCondition = index => ({
	type: REMOVE_CONDITION,
	index
})

export const EDIT_RULE = 'EDIT_RULE'
export const editRule = (index, uuid) => ({
	type: EDIT_RULE,
	uuid,
	index
})

export const REVERT_RULE = 'REVERT_RULE'
export const revertRule = (index, uuid) => ({
	type: REVERT_RULE,
	uuid,
	index
})

export const REVERT_RULE_SET = 'REVERT_RULE_SET'
export const revertRuleSet = uuid => ({
	type: REVERT_RULE_SET,
	uuid
})

export const CANCEL_EDITING_RULE = 'CANCEL_EDITING_RULE'
export const cancelEditingRule = () => ({
	type: CANCEL_EDITING_RULE
})

export const CONFIRM_EDITING_RULE = 'CONFIRM_EDITING_RULE'
export const confirmEditingRule = (index, uuid, data) => ({
	type: CONFIRM_EDITING_RULE,
	index,
	uuid,
	data
})

export const ADD_RULE_SET = 'ADD_RULE_SET'
export const addRuleSet = () => ({
	type: ADD_RULE_SET
})

export const REMOVE_RULE_SET = 'REMOVE_RULE_SET'
export const removeRuleSet = uuid => ({
	type: REMOVE_RULE_SET,
	uuid
})

export const ADD_RULE = 'ADD_RULE'
export const addRule = uuid => ({
	type: ADD_RULE,
	uuid
})

export const REMOVE_RULE = 'REMOVE_RULE'
export const removeRule = (index, uuid) => ({
	type: REMOVE_RULE,
	uuid,
	index
})

export const REORDER_RULES = 'REORDER_RULES'
export const reorderRules = (ruleSetId, result) => ({
	type: REORDER_RULES,
	ruleSetId,
	result
})

export const RULE_SET_REQUEST_STATE = {
	initial: 0,
	inProgress: 1,
	success: 2,
	failure: 3
}

export const FETCH_RULESETS_REQUEST = 'FETCH_RULESETS_REQUEST'
export const requestRuleSets = () => ({
	type: FETCH_RULESETS_REQUEST
})

export const FETCH_RULESETS_SUCCESS = 'FETCH_RULESETS_SUCCESS'
export const receiveRuleSets = ruleSets => ({
	type: FETCH_RULESETS_SUCCESS,
	ruleSets
})

export const FETCH_RULESETS_FAILURE = 'FETCH_RULESETS_FAILURE'
export const failReceiveRuleSets = (error) => ({
	type: FETCH_RULESETS_FAILURE,
	error: {
		...error,
		reason: FETCH_RULESETS_FAILURE
	}
})

export const SAVE_RULE_SET_REQUEST = 'SAVE_RULE_SET_REQUEST'
export const saveRuleSetRequest = uuid => ({
	type: SAVE_RULE_SET_REQUEST,
	ruleSetId: uuid
})

export const OPEN_SAVE_MODAL_CONFIRMATION_DIALOG =
	'OPEN_SAVE_MODAL_CONFIRMATION_DIALOG'
export const openSaveModalConfirmationDialog = () => ({
	type: OPEN_SAVE_MODAL_CONFIRMATION_DIALOG
})

export const CLOSE_SAVE_MODAL_CONFIRMATION_DIALOG =
	'CLOSE_SAVE_MODAL_CONFIRMATION_DIALOG'
export const closeSaveModalConfirmationDialog = () => ({
	type: CLOSE_SAVE_MODAL_CONFIRMATION_DIALOG
})

export const RULE_SET_SAVED = 'RULE_SET_SAVED'
export const saveRuleSetSaved = ruleSetId => ({
	type: RULE_SET_SAVED,
	ruleSetId: ruleSetId
})

export const SAVE_RULE_SET_SUCCESS = 'SAVE_RULE_SET_SUCCESS'
export const saveRuleSetSuccess = (uuid, ruleSet) => ({
	type: SAVE_RULE_SET_SUCCESS,
	ruleSetId: uuid,
	ruleSet
})

export const SAVE_RULE_SET_FAIL = 'SAVE_RULE_SET_FAIL'
export const saveRuleSetFail = (uuid, status) => ({
	type: SAVE_RULE_SET_FAIL,
	ruleSetId: uuid,
	status
})

export const ruleSetsUrl = fulfillmentLocation =>
	`/api/v0/fulfillment-locations/${fulfillmentLocation}/rule-sets`

export const fetchRuleSets = locationId => async (dispatch, getState) => {
	const state = getState()

	if (ruleSets.isFetching(state)) {
		return null
	}
	dispatch(requestRuleSets())

	let response
	try {
		response = await sortManApiClient.get(ruleSetsUrl(locationId))
		if (response && response.data) {
			dispatch(receiveRuleSets(response.data))
		} else {
			throw new Error('Empty response while fetching rule sets')
		}
	} catch (error) {
		dispatch(
			failReceiveRuleSets(error)
		)
	}
}

export const saveRuleSet = (locationId, uuid) => async (dispatch, getState) => {
	if (!locationId) {
		dispatch(saveRuleSetFail(uuid, null))
		return
	}

	dispatch(saveRuleSetRequest(uuid))

	const ruleSet = ruleSets.getRuleSet(getState(), uuid)
	const isExisting = !!ruleSet.uuid

	let url = ruleSetsUrl(locationId)
	if (isExisting) {
		url += `/${ruleSet.uuid}`
	}

	try {
		const response = await sortManApiClient.request({
			url,
			method: isExisting ? 'put' : 'post',
			data: normalizeForBackend(ruleSet)
		})
		dispatch(saveRuleSetSuccess(uuid, response.data))
	} catch (e) {
		dispatch(saveRuleSetFail(uuid, e.statusCode))
	}
}

export const FILTER_RULES = 'FILTER_RULES'
export const filterRules = searchText => ({
	type: FILTER_RULES,
	searchText
})

export const SORT_INFO_CHANGE = 'SORT_INFO_CHANGE'
export const sortInfoChange = (sortStrategyId, sortInfoKey, value) => ({
	type: SORT_INFO_CHANGE,
	sortStrategyId,
	sortInfoKey,
	value
})

export const CHANGE_CONDITION = 'CHANGE_CONDITION'
export const changeCondition = (index, value, field) => ({
	type: CHANGE_CONDITION,
	index,
	value,
	field
})

export const REMOVE_SORT_INFO = 'REMOVE_SORT_INFO'
export const removeSortInfo = (sortInfoKey, sortStrategyId) => ({
	type: REMOVE_SORT_INFO,
	sortInfoKey,
	sortStrategyId
})

export const ADD_SORT_INFO = 'ADD_SORT_INFO'
export const addSortInfo = (sortInfoKey, sortStrategyId) => ({
	type: ADD_SORT_INFO,
	sortInfoKey,
	sortStrategyId
})

export const CHANGE_RULE_SET_CONFIGURATION = 'CHANGE_RULE_SET_CONFIGURATION'
export const changeRuleSetConfiguration = (ruleSetId, data) => ({
	type: CHANGE_RULE_SET_CONFIGURATION,
	ruleSetId,
	data
})

export const CONFIRM_RULE_SET_CONFIGURATION = 'CONFIRM_RULE_SET_CONFIGURATION'
export const confirmRuleSetConfiguration = ruleSetId => ({
	type: CONFIRM_RULE_SET_CONFIGURATION,
	ruleSetId
})

export const DISCARD_RULE_SET_CONFIGURATION = 'DISCARD_RULE_SET_CONFIGURATION'
export const discardRuleSetConfiguration = () => ({
	type: DISCARD_RULE_SET_CONFIGURATION
})

export const CHANGE_DESCRIPTION = 'CHANGE_DESCRIPTION'
export const changeDescription = (ruleSetId, description) => ({
	type: CHANGE_DESCRIPTION,
	ruleSetId,
	description
})
