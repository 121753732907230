import React, { Component } from 'react'

export class Snackbar extends Component {
	constructor(props) {
		super(props)
		this.close = this.close.bind(this)
		this.state = {
			style: {
				position: 'fixed',
				left: 0,
				right: 0,
				top: this.props.position || '15px',
				transition: 'top 300ms cubic-bezier(0, 0, 0.30, 1)',
				zIndex: '2000',
				width: '60%',
				margin: 'auto'
			}
		}
		if (props.timeout) {
			setTimeout(() => this.close(), props.timeout)
		}
	}

	close() {
		if (this.props.onCloseCallback) {
			this.props.onCloseCallback()
		}
		return this.setState({
			style: {
				...this.state.style,
				top: !this.state.open
					? `-${(this.props.position || 0) + 100}px`
					: '15px'
			}
		})
	}

	render() {
		return (
			<div
				className={
					this.props.level === 'danger'
						? 'alert alert-dismissible alert-danger'
						: this.props.level === 'warning'
							? 'alert alert-dismissible alert-warning'
							: 'alert alert-dismissible alert-success'
				}
				style={this.state.style}
			>
				<button
					type="button"
					className="close"
					data-dismiss="alert"
					onClick={this.close}
				>
					&times;
				</button>
				{this.props.children}
			</div>
		)
	}
}
