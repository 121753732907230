export const ruleSets = {
	isFetching: state => state.ruleSets.isFetching,
	searchText: state => state.ruleSets.searchText,
	getRuleSet: (state, ruleSetId) =>
		state.ruleSets.ruleSets.find(ruleSet => ruleSet.uuid === ruleSetId),
	getRuleSets: state => state.ruleSets.ruleSets,
	getEditedRule: state => state.ruleSets.editedRule,
	getEditedRuleSetConfiguration: state =>
		state.ruleSets.editedRuleSetConfiguration,
	descriptions: state => state.ruleSets.descriptions
}
export const capi = {
	isFetching: state => state.capi.isFetchingCarrierServices,
	carrierServices: state => state.capi.carrierServices
}

export const boxman = {
	isFetching: state => state.boxman.isFetching,
	shippingBoxes: state => state.boxman.shippingBoxes
}

export const pickupSchedules = {
	isFetching: state => state.pickupSchedules.isFetching,
	pickupSchedules: state => state.pickupSchedules.pickupSchedules
}

export const sortStrategies = {
	isFetching: state => state.sortStrategies.isFetching,
	sortStrategies: state => state.sortStrategies.strategies
}

export const ruleSetHistory = {
	isFetching: state => state.history.isFetching,
	history: state => state.history.history,
	size: state => state.history.history.length
}

export const errors = {
	// Pickup schedules currently not taken out
	// if needed add in state.pickupSchedules.error
	collectFrom: state =>
		[
			state.ruleSets.error,
			state.boxman.error,
			state.capi.error,
			state.sortStrategies.error,
			state.locations.error
		].filter(err => err)
}
