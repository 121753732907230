import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Snackbar } from '../../common/components/Snackbar'
import socketIOClient from 'socket.io-client'
import auth from '../../common/auth/auth'
import { getSortMan } from '../../config'

import {
	closeRuleSetNotification,
	ruleSetEditedByAnotherUser
} from '../../actions/ruleSetNotification'

class EditNotify extends Component {
	constructor(props) {
		super(props)
		this.close = this.close.bind(this)
		const socket = socketIOClient(getSortMan().baseURL, {
			query: `auth_token=${auth.getAccessToken()}`
		})
		const ruleSetIds = this.props.ruleSets.map(ruleSet => ruleSet.uuid)
		socket.emit('rules', { ruleSetIds: ruleSetIds })
		socket.on('rules', data => {
			const notify = data.changedRuleSet.savedBy.sub !== auth.getProfile().sub
			if (notify) {
				this.props.ruleSetEditedByAnotherUser(data.changedRuleSet)
			}
		})
		socket.on('reconnect', () => {
			//resend ruleSetIds to the server on reconnect if the the connection was lost
			socket.emit('rules', { ruleSetIds: ruleSetIds })
		})
	}

	close() {
		this.props.closeRuleSetNotification()
	}

	render() {
		const displayNotification = notification => {
			return (
				<Snackbar level="warning" onCloseCallback={() => this.close()}>
					<h4>Rules {notification.name}</h4>
					were changed by another user <b>{notification.savedBy.name}</b>
					<p>
						with description: <i>{notification.description}</i>
					</p>
					<p>
						You will not be able to save your current changes. Please refresh
						the page. Changes to the {notification.name} will be lost
					</p>
				</Snackbar>
			)
		}
		return (
			<div>
				{this.props.show
					? displayNotification(this.props.notification)
					: undefined}
			</div>
		)
	}
}

export default connect(
	state => ({
		notification: state.notification.notification,
		show: state.notification.show
	}),
	{
		closeRuleSetNotification,
		ruleSetEditedByAnotherUser
	}
)(EditNotify)
