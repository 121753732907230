import * as actions from '../actions/ruleSetNotification'

export default (
	state = {
		show: false
	},
	action
) => {
	switch (action.type) {
		case actions.RULE_SET_EDITED_BY_ANOTHER_USER:
			return { ...state, show: true, notification: action.notification }
		case actions.CLOSE_RULE_SET_NOTIFICATION:
			return { ...state, show: false }
		default:
			return state
	}
}
