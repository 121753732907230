import * as ActionTypes from '../actions/auth'
import auth from '../common/auth/auth'

export default (
	state = {
		isAuthenticated: auth.isLoggedIn(),
		profile: auth.getProfile(),
		error: ''
	},
	action
) => {
	switch (action.type) {
		case ActionTypes.LOGIN_SUCCESS:
			return {
				isAuthenticated: true,
				profile: action.profile
			}
		case ActionTypes.LOGOUT_SUCCESS:
			return {
				isAuthenticated: false,
				profile: null
			}
		case ActionTypes.LOGIN_ERROR:
			return {
				isAuthenticated: false,
				profile: null,
				error: action.error
			}
		default:
			return state
	}
}
