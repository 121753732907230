import { centralizedAuth as authWrapper } from '@cimpress/simple-auth-wrapper'
import { getAuth0 } from '../../config'

const auth = new authWrapper({
  clientID: getAuth0().clientId,
  redirectRoute: '',
  domain: 'cimpress.auth0.com',
  audience: 'https://api.cimpress.io/'
})

export default auth
