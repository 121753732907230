import {
	FETCH_LOCATIONS_REQUEST,
	FETCH_LOCATIONS_SUCCESS,
	FETCH_LOCATIONS_FAILURE,
	UPDATE_CURRENT_LOCATION,
	ERROR_GETTING_LOCATION
} from '../actions/locations'

export default (
	state = {
		locations: [],
		isFetching: false,
		message: null,
		locationInformation: null,
		code: null,
		error: null,
		isSuccessfullyLoaded: false
	},
	action
) => {
	switch (action.type) {
		case FETCH_LOCATIONS_REQUEST:
			return {
				...state,
				isFetching: true,
				message: null,
				code: null,
				error: null,
				isSuccessfullyLoaded: false
			}

		case FETCH_LOCATIONS_SUCCESS:
			return {
				...state,
				isFetching: false,
				locations: action.locations,
				error: null,
				isSuccessfullyLoaded: true
			}

		case FETCH_LOCATIONS_FAILURE:
			return {
				...state,
				isFetching: false,
				locations: [],
				message: action.message,
				code: action.statusCode,
				error: null,
				isSuccessfullyLoaded: false
			}

		case UPDATE_CURRENT_LOCATION:
			return {
				...state,
				currentLocationId: action.locationId,
				locationInformation: action.locationInformation,
				error: null
			}

		case ERROR_GETTING_LOCATION:
			return {
				...state,
				currentLocationId: action.locationId,
				locationInformation: null,
				error: action.error
			}

		default:
			return state
	}
}
