import { combineReducers } from 'redux'
import auth from './auth'
import ruleSets from './ruleSets'
import capi from './capi'
import pickupSchedules from './pickupSchedule'
import ruleSetHistory from './ruleSetHistory'
import ruleSetNotification from './ruleSetNotification'
import sortStrategies from './sortStrategies'
import locations from './locations'
import boxman from './boxman'
import { routerReducer } from 'react-router-redux'
export default combineReducers({
	auth,
	ruleSets,
	capi,
	boxman,
	pickupSchedules,
	sortStrategies,
	history: ruleSetHistory,
	router: routerReducer,
	notification: ruleSetNotification,
	locations
})
