import * as actions from '../actions/ruleSets'
import * as _ from 'lodash'
import { revertRule, revertRuleSet } from './ruleSets/revert'
import { removeRule } from './ruleSets/remove'
import { confirmEditingRule, editRule } from './ruleSets/edit'
import {
	confirmRuleSetConfigurationChange,
	discardRuleSetConfigurationChange,
	triggerRuleSetConfigurationChange
} from './ruleSets/configure'

const decorateWithVisible = rules =>
	rules
		? rules.map((rule, index) => ({
				...rule,
				index,
				isVisible: true
			}))
		: rules

export default (
	state = {
		originalRuleSets: [],
		ruleSets: [],
		isFetching: false,
		searchText: '',
		editedRule: null,
		editedRuleSetConfiguration: null,
		openSaveModal: false,
		descriptions: {}
	},
	action
) => {
	switch (action.type) {
		case actions.FETCH_RULESETS_REQUEST:
			return { ...state, isFetching: true }

		case actions.FETCH_RULESETS_SUCCESS:
			let processedRuleSets = action.ruleSets.map(ruleSet => ({
				...ruleSet,
				// Index is assigned once as soon as data is fetched from data source, this index should not be changed by any of the follow-up actions (filtering, deleting, etc.)
				// Index should be filtered our __BEFORE__ data is sent to the server
				rules: decorateWithVisible(ruleSet.rules)
			}))

			return {
				...state,
				ruleSets: processedRuleSets,
				originalRuleSets: processedRuleSets,
				isFetching: false
			}
		case actions.FETCH_RULESETS_FAILURE:
			return {
				...state,
				isFetching: false,
				error: action.error
			}
		case actions.FILTER_RULES:
			const lowerCasedSearchText = action.searchText.toLowerCase()
			return {
				...state,
				searchText: action.searchText,
				ruleSets: state.ruleSets.map(ruleSet => ({
					...ruleSet,
					rules: ruleSet.rules.map(rule => {
						return {
							...rule,
							// TODO: Consider more explicit filtering algorithm
							isVisible:
								action.searchText === '' ||
								JSON.stringify(rule)
									.toLowerCase()
									.includes(lowerCasedSearchText)
						}
					})
				}))
			}
		case actions.ADD_CONDITION:
			return {
				...state,
				editedRule: {
					...state.editedRule,
					data: {
						...state.editedRule.data,
						conditions: [
							...state.editedRule.data.conditions.slice(),
							{
								type: 'shipping-carrier',
								value: '',
								expression: 'eq'
							} // FIXME: Initialize new condition properly
						]
					}
				}
			}
		case actions.REMOVE_CONDITION:
			return {
				...state,
				editedRule: {
					...state.editedRule,
					data: {
						...state.editedRule.data,
						conditions: [
							...state.editedRule.data.conditions.slice(0, action.index),
							...state.editedRule.data.conditions.slice(action.index + 1)
						]
					}
				}
			}
		case actions.REMOVE_RULE_SET:
			return {
				...state,
				ruleSets: state.ruleSets.filter(ruleSet => ruleSet.uuid !== action.uuid)
			}
		case actions.ADD_RULE:
			const targetRuleSet = state.ruleSets.find(
				ruleSet => ruleSet.uuid === action.uuid
			)
			// Unexpected, incorrect ID
			if (!targetRuleSet) {
				return state
			}
			// Gathers all sort information field names used in a ruleset
			const sortInfoFields = targetRuleSet.rules.reduce((carry, current) => {
				current.sortInformation.forEach(sortInfo => {
					Object.keys(sortInfo.data).forEach(field => {
						// Required for backwards compatibility - server may already return inconsistent data where sortStrategyId is not defined
						if (!carry.has(field) || carry.get(field) === undefined) {
							carry.set(field, sortInfo.sortStrategyId)
						}
					})
				})
				return carry
			}, new Map())

			// Maps sort strategy identifier to list of field names
			const sortInformation = {}
			sortInfoFields.forEach((key, value) => {
				if (!sortInformation[key]) {
					sortInformation[key] = []
				}
				sortInformation[key].push(value)
			})

			return {
				...state,
				editedRule: {
					index: null,
					ruleSetId: action.uuid,
					data: {
						conditions: [],
						sortInformation: Object.keys(sortInformation).map(
							sortStrategyId => {
								return {
									sortStrategyId,
									data: sortInformation[sortStrategyId].reduce(
										(carry, current) => {
											carry[current] = undefined
											return carry
										},
										{}
									)
								}
							}
						)
					}
				}
			}
		case actions.REMOVE_RULE:
			return removeRule(state, action)

		case actions.REORDER_RULES:
			return {
				...state,
				ruleSets: state.ruleSets.map(ruleSet => {
					if (ruleSet.uuid !== action.ruleSetId || !action.result.destination) {
						return ruleSet
					}

					// Translates "visible" index to "real" index of underlying data that may not be currently visible due to filtering
					const findUnderlyingIndexes = (
						rules,
						visibleSourceIndex,
						visibleDestIndex
					) => {
						let sourceIndex,
							destIndex = NaN
						let visibleCounter = 0

						for (let i = 0; i < rules.length; i++) {
							if (rules[i].isVisible) {
								if (visibleCounter === visibleSourceIndex) {
									sourceIndex = i
								} else if (visibleCounter === visibleDestIndex) {
									destIndex = i
								}
								visibleCounter++
							}
						}
						return { sourceIndex, destIndex }
					}

					const reorder = (list, startIndex, endIndex) => {
						const result = Array.from(list)
						const [removed] = result.splice(startIndex, 1)
						result.splice(endIndex, 0, removed)

						return result
					}

					const { sourceIndex, destIndex } = findUnderlyingIndexes(
						ruleSet.rules,
						action.result.source.index,
						action.result.destination.index
					)

					return {
						...ruleSet,
						rules: reorder(ruleSet.rules, sourceIndex, destIndex).map(
							(rule, index) => {
								if (destIndex === index) {
									return { ...rule, isReordered: true }
								}
								return rule
							}
						)
					}
				})
			}

		case actions.SAVE_RULE_SET_REQUEST:
			return {
				...state,
				ruleSets: state.ruleSets.map(ruleSet => {
					if (ruleSet.uuid === action.ruleSetId) {
						return {
							...ruleSet,
							state: actions.RULE_SET_REQUEST_STATE.inProgress,
							description: state.descriptions
								? state.descriptions[ruleSet.uuid]
								: undefined
						}
					}
					return { ...ruleSet, state: actions.RULE_SET_REQUEST_STATE.initial }
				}),
				descriptions: {
					...state.descriptions,
					[action.ruleSetId]: undefined
				}
			}

		case actions.SAVE_RULE_SET_SUCCESS:
			const decorateSavedRuleSet = ruleSet => {
				return {
					...ruleSet,
					rules: decorateWithVisible(ruleSet.rules),
					state: actions.RULE_SET_REQUEST_STATE.success
				}
			}
			return {
				...state,
				ruleSets: state.ruleSets.map(ruleSet => {
					if (ruleSet.uuid === action.ruleSetId) {
						return decorateSavedRuleSet(action.ruleSet)
					}
					return ruleSet
				}),
				originalRuleSets: (ruleSets => {
					const wasRuleSetNew = !ruleSets.find(
						ruleSet => ruleSet.uuid === action.ruleSetId
					)
					if (wasRuleSetNew) {
						return [...ruleSets, decorateSavedRuleSet(action.ruleSet)]
					} else {
						return ruleSets.map(ruleSet => {
							if (ruleSet.uuid === action.ruleSetId) {
								return decorateSavedRuleSet(action.ruleSet)
							}
							return ruleSet
						})
					}
				})(state.originalRuleSets)
			}

		case actions.SAVE_RULE_SET_FAIL:
			return {
				...state,
				ruleSets: state.ruleSets.map(ruleSet => {
					if (ruleSet.uuid === action.ruleSetId) {
						return {
							...ruleSet,
							state: actions.RULE_SET_REQUEST_STATE.failure,
							errorCode: action.status
						}
					}
					return { ...ruleSet, state: actions.RULE_SET_REQUEST_STATE.initial }
				})
			}

		case actions.SORT_INFO_CHANGE:
			return {
				...state,
				editedRule: {
					...state.editedRule,
					data: {
						...state.editedRule.data,
						sortInformation: state.editedRule.data.sortInformation.map(
							sortInfo => {
								if (action.sortStrategyId !== sortInfo.sortStrategyId) {
									return sortInfo
								}
								return {
									...sortInfo,
									data: {
										...sortInfo.data,
										[action.sortInfoKey]: action.value
									}
								}
							}
						)
					}
				}
			}

		case actions.CHANGE_CONDITION:
			return {
				...state,
				editedRule: {
					...state.editedRule,
					data: {
						...state.editedRule.data,
						conditions: state.editedRule.data.conditions.map(
							(condition, index) => {
								if (action.index !== index) {
									return condition
								}
								return {
									...condition,
									[action.field]: action.value
								}
							}
						)
					}
				}
			}
		case actions.CHANGE_RULE_SET_CONFIGURATION:
			return triggerRuleSetConfigurationChange(state, action)

		case actions.CONFIRM_RULE_SET_CONFIGURATION:
			return confirmRuleSetConfigurationChange(state, action)

		case actions.DISCARD_RULE_SET_CONFIGURATION:
			return discardRuleSetConfigurationChange(state, action)

		case actions.REVERT_RULE:
			return revertRule(state, action)

		case actions.REVERT_RULE_SET:
			return revertRuleSet(state, action)

		case actions.EDIT_RULE:
			return editRule(state, action)

		case actions.CANCEL_EDITING_RULE:
			return {
				...state,
				editedRule: null
			}

		case actions.CONFIRM_EDITING_RULE:
			return confirmEditingRule(state, action)

		case actions.REMOVE_SORT_INFO:
			return {
				...state,
				editedRule: {
					...state.editedRule,
					data: {
						...state.editedRule.data,
						sortInformation: state.editedRule.data.sortInformation.map(
							sortInfo => {
								if (action.sortStrategyId !== sortInfo.sortStrategyId) {
									return sortInfo
								}
								return {
									...sortInfo,
									data: _.omit(sortInfo.data, action.sortInfoKey)
								}
							}
						)
					}
				}
			}
		case actions.ADD_SORT_INFO:
			// Used to determine whether we should just extend current sort strategy attributes or append configuration as a new sort strategy attribute
			const isSortStrategyConfigured = state.editedRule.data.sortInformation.some(
				sortInfo => sortInfo.sortStrategyId === action.sortStrategyId
			)
			return {
				...state,
				editedRule: {
					...state.editedRule,
					data: {
						...state.editedRule.data,
						sortInformation: isSortStrategyConfigured
							? state.editedRule.data.sortInformation.map(sortInfo => {
									if (action.sortStrategyId !== sortInfo.sortStrategyId) {
										return sortInfo
									}
									return {
										...sortInfo,
										data: {
											...sortInfo.data,
											[action.sortInfoKey]: undefined
										}
									}
								})
							: state.editedRule.data.sortInformation.concat({
									sortStrategyId: action.sortStrategyId,
									data: {
										[action.sortInfoKey]: undefined
									}
								})
					}
				}
			}
		case actions.OPEN_SAVE_MODAL_CONFIRMATION_DIALOG:
			return {
				...state,
				openSaveModal: true
			}
		case actions.CLOSE_SAVE_MODAL_CONFIRMATION_DIALOG:
			return {
				...state,
				openSaveModal: false
			}
		case actions.RULE_SET_SAVED:
			return {
				...state,
				ruleSets: state.ruleSets.map(ruleSet => {
					if (ruleSet.uuid !== action.ruleSetId) {
						return ruleSet
					}
					return {
						...ruleSet,
						state: actions.RULE_SET_REQUEST_STATE.initial
					}
				})
			}

		case actions.CHANGE_DESCRIPTION:
			return {
				...state,
				descriptions: {
					...state.descriptions,
					[action.ruleSetId]: action.description
				}
			}
		default:
			return state
	}
}
