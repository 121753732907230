import React, { Component } from 'react'
import * as countries from 'country-list'
import { Select } from '@cimpress/react-components'

export const expressionOptions = [
	{ value: 'eq', label: '=' }
]

export class Country extends Component {
	render() {
		const codeList = countries().getCodeList()
		const countryNames = Object.keys(codeList).map(key => {
			return { value: key.toUpperCase(), label: codeList[key] }
		})
		return (
			<Select
				name="y"
				value={this.props.value}
				options={countryNames}
				onChange={this.props.changeConditionValue}
				id={`sort-info-text-${this.props.index}`}
			/>
		)
	}
}
