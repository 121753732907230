import * as actions from '../actions/capi'

const detectDuplicates = carrierServices => {
	// Detect duplicates (consider different casing to represent same name - still misleading)
	const grouped = {}
	Object.keys(carrierServices).forEach(carrierService => {
		const sanitizedName = carrierServices[carrierService].name.toLowerCase()
		grouped[sanitizedName] = grouped[sanitizedName] || []
		grouped[sanitizedName].push(carrierService)
	})

	const decorated = {}
	Object.keys(grouped).forEach(sanitizedName => {
		grouped[sanitizedName].forEach(carrierService => {
			decorated[carrierService] = {
				name: carrierServices[carrierService].name,
				hasSimilarName: grouped[sanitizedName].length !== 1,
				capabilities: carrierServices[carrierService].capabilities
			}
		})
	})

	return decorated
}

export default (
	state = { carrierServices: {}, isFetchingCarrierServices: false },
	action
) => {
	switch (action.type) {
		case actions.FETCH_CARRIER_SERVICES_REQUEST:
			return { ...state, isFetchingCarrierServices: true }
		case actions.FETCH_CARRIER_SERVICES_SUCCESS:
			return {
				...state,
				carrierServices: detectDuplicates(action.carrierServices),
				isFetchingCarrierServices: false
			}
		case actions.FETCH_CARRIER_SERVICES_FAILURE:
			return {
				...state,
				isFetchingCarrierServices: false,
				error: action.error
			}
		default:
			return state
	}
}
