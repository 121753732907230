import React, { PureComponent } from 'react'

export default class LocationNotConfigured extends PureComponent {
	constructor() {
		super()
		this.onAdd = this.onAdd.bind(this)
	}

	onAdd() {
		this.props.onAdd(null, { fulfillmentLocationId: this.props.locationId })
	}

	render() {
		return (
			<div>
				<h4 className="text-center">
					No rules are configured for selected fulfillment location
				</h4>
				<br />
				<button
					className="center-block btn btn-primary add"
					onClick={this.onAdd}
				>
					<i className="fa fa-plus" aria-hidden="true" /> Create new rule set
				</button>
			</div>
		)
	}
}
