import React, { Component } from 'react'
import { connect } from 'react-redux'
import './Container.css'

class Contact extends Component {
	render() {
		return (
			<div className="container container-fluid container-vertical-stretch">
				<div className="row">
					<div className="col-md-12">
						<h2>
							<i className="fa fa-send" /> Contact
						</h2>
						<p>
							Contact us at{' '}
							<a href="mailto:ShipSorterSupport@cimpress.com">
								ShipSorterSupport@cimpress.com
							</a>! We'll do our best to respond as soon as possible.
						</p>
						<p>
							If you have a urgent issues outside of US business hours (9 AM to
							5 PM EST), please contact{' '}
							<a href="mailto:page-sortman@cimpress.pagerduty.com">
								page-sortman@cimpress.pagerduty.com
							</a>
						</p>
					</div>
				</div>
			</div>
		)
	}
}

export default connect()(Contact)
