import React, { Component } from 'react'
import { Condition } from '../Condition'
import { DropDownButton } from '../../common/components/DropDownButton'
import { flatten } from 'lodash'
import './RuleForm.css'
import { SortInformationRow } from './SortInformationRow'

export class RuleForm extends Component {
	constructor(props) {
		super(props)
		this.addSortInfo = this.addSortInfo.bind(this)
	}

	addSortInfo(key, sortStrategyId) {
		return () => {
			this.props.addSortInfo(key, sortStrategyId)
      this.props.sortInfoChange(sortStrategyId, key, '')
		}
	}

	render() {
		// Collect all sort info attribute names (across multiple sort strategies)
		const usedAttributes = flatten(
			this.props.rule.sortInformation.map(sortInfo =>
				Object.keys(sortInfo.data)
			)
		)

		const shippingCarrierCondition = this.props.rule.conditions.find(condition => condition.type === 'shipping-carrier')
		const selectedCarrierService = shippingCarrierCondition && shippingCarrierCondition.value

		const sortInfoAttributes = flatten(
			this.props.sortStrategies.strategies.map(sortStrategy =>
				sortStrategy.attributes
					// render only EDITABLE fields and DO NOT render attribute fields that were already configured
					.filter(
						attribute =>
							attribute.editable && !usedAttributes.includes(attribute.name)
					)
					.map(attribute => ({
						name: attribute.name,
						sortStrategyId: sortStrategy.uuid,
						title: attribute.title
					}))
			)
		)

		const additionalConditionTypes = flatten(
			this.props.sortStrategies.strategies.map(sortStrategy =>
				sortStrategy.additionalConditionTypes || []
			)
		)

		const typeOptions = [
			{
				value: 'country',
				label: 'Country'
			},
			{
				value: 'shipping-carrier',
				label: 'Shipping Carrier Service'
			},
			{
				value: 'weight',
				label: 'Weight'
			},
			{
				value: 'dimensions',
				label: 'Dimensions'
			},
			{
				value: 'postal-code',
				label: 'Postal Code'
			},
			{
				value: 'shipping-box',
				label: 'Shipping Box'
			},
			{
				value: 'carrier-service-options',
				label: 'Carrier Service Options'
			}
		].concat(additionalConditionTypes)


		const conditionTypesAllowedOnlyOnce = [
			'shipping-carrier',
			'sortation-type',
			'postal-code',
			'shipping-box',
			'tariff-category'
		]
		let allowedConditionTypes = typeOptions

		return (
			<div className="rule">
				<div className="row">
					<div className="col-md-12">
						<h4>When conditions match</h4>
						<div>
							{this.props.rule.conditions.map((condition, index) => {
								let allowedTypesForThisCondition = allowedConditionTypes
								if (conditionTypesAllowedOnlyOnce.includes(condition.type)) {
									allowedConditionTypes = allowedConditionTypes.filter(type => type.value !== condition.type)
								}
								return (
									<Condition
										key={index}
										condition={condition}
										carrierServices={this.props.carrierServices}
										removeCondition={this.props.removeCondition}
										allowedConditionTypes={allowedTypesForThisCondition}
										selectedCarrierService={selectedCarrierService}
										index={index}
										changeCondition={this.props.changeCondition}
										shippingBoxes={this.props.shippingBoxes}
										additionalConditionTypes={additionalConditionTypes}
									/>
								)
							})}
						</div>
						<div>
							<button
								className="btn-outline-primary btn"
								onClick={this.props.addCondition}
							>
								<i className="fa fa-plus" aria-hidden="true" /> Add a condition
							</button>
						</div>
					</div>
				</div>
				<hr />
				<div className="row">
					<div className="col-md-12">
						<h4>Then apply sort information</h4>
						<div>
							{this.props.rule.sortInformation.map((piece, index) => (
								<SortInformationRow {...this.props} piece={piece} key={index} />
							))}
						</div>
						<DropDownButton title="Add Sort Information">
							{sortInfoAttributes.length > 0
								? sortInfoAttributes.map((attribute, index) => {
										return (
											<li
												key={index}
												onClick={this.addSortInfo(
													attribute.name,
													attribute.sortStrategyId
												)}
											>
												<button className='sort-info-btn'>{attribute.title}</button>
											</li>
										)
									})
								: undefined}
						</DropDownButton>
					</div>
				</div>
			</div>
		)
	}
}
