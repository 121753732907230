import React, { Component } from 'react'

export default class Controls extends Component {
	render() {
		const { isRemoved, isChanged, isAdded, isReordered } = this.props.rule
		const isRevertAllowed = (isRemoved || isChanged || isReordered) && !isAdded
		return (
			<div>
				{isRevertAllowed && (
					<button
						className="btn btn-link revert"
						title="Revert changes to original version"
						onClick={this.props.revertRule}
					>
						<i className="fa fa-undo" />
					</button>
				)}
				{!isRemoved && (
					<span>
						<button
							className="btn btn-link edit"
							title="Change rule"
							onClick={this.props.editRule}
						>
							<i className="fa fa-pencil" />
						</button>
						<button
							className="btn btn-link remove"
							title="Remove rule"
							onClick={this.props.removeRule}
						>
							<i className="fa fa-trash" />
						</button>
					</span>
				)}
			</div>
		)
	}
}
