import React, { Component } from 'react'
import { Select } from '@cimpress/react-components'

export const expressionOptions = [
  { value: 'eq', label: '=' }
]

export class SortType extends Component {
  render() {
    const types = ['Manual', 'Automated'].map(label => ({label, value: label}))

    return (
      <Select
        name="st"
        value={this.props.value}
        options={types}
        onChange={this.props.changeConditionValue}
        id={`sort-type-text-${this.props.index}`}
      />
    )
  }
}
