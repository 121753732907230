import React from 'react'
import { ConnectedRouter } from 'react-router-redux'
import configureStore, { history } from '../redux/configureStore'
import { Provider } from 'react-redux'
import App from './App'

const store = configureStore()

export default () => {
	return (
		<Provider store={store}>
			<ConnectedRouter history={history}>
				<App />
			</ConnectedRouter>
		</Provider>
	)
}
