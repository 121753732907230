export const RULE_SET_EDITED_BY_ANOTHER_USER = 'RULE_SET_EDITED_BY_ANOTHER_USER'
const ruleSetEditedByAnotherUser = notification => ({
	type: RULE_SET_EDITED_BY_ANOTHER_USER,
	notification
})

export const CLOSE_RULE_SET_NOTIFICATION = 'CLOSE_RULE_SET_NOTIFICATION'
const closeRuleSetNotification = () => ({
	type: CLOSE_RULE_SET_NOTIFICATION
})

export { ruleSetEditedByAnotherUser, closeRuleSetNotification }
