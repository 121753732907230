import React, { Component } from 'react'
import { CarrierServiceOptions, expressionOptions as carrierServiceOptionsExpressionOptions } from './Conditions/CarrierServiceOptions'
import { Country, expressionOptions as countryExpressionOptions } from './Conditions/Country'
import { PostalCode, expressionOptions as postalCodeExpressionOptions } from './Conditions/PostalCode'
import { expressionOptions as weightExpressionOptions } from './Conditions/Weight'
import Weight from './Conditions/Weight'
import { expressionOptions as dimensionsExpressionOptions } from './Conditions/Dimensions'
import Dimensions from './Conditions/Dimensions'
import { ShippingCarrierService, expressionOptions as carrierServiceExpressionOptions } from './Conditions/ShippingCarrierService'
import { TariffCategory, expressionOptions as tariffCategoryExpressionOptions } from './Conditions/TariffCategory'
import { ShippingBox, expressionOptions as shippingBoxExpressionOptions } from './Conditions/ShippingBox'
import { SortType, expressionOptions as sortTypeExpressionOptions } from './Conditions/SortType'
import './Condition.css'
import { Select } from '@cimpress/react-components'

export const allExpressionOptions = {
	'dimensions': dimensionsExpressionOptions,
	'shipping-carrier': carrierServiceExpressionOptions,
	'weight': weightExpressionOptions,
	'country': countryExpressionOptions,
	'postal-code': postalCodeExpressionOptions,
	'tariff-category': tariffCategoryExpressionOptions,
	'sortation-type': sortTypeExpressionOptions,
	'shipping-box': shippingBoxExpressionOptions,
	'carrier-service-options': carrierServiceOptionsExpressionOptions
}

export class Expression extends Component {
	constructor(props) {
		super(props)
		this.changeConditionExpression = this.changeConditionExpression.bind(this)
	}

	changeConditionExpression(selected) {
		if (!selected) {
			return
		}
		this.props.changeCondition(this.props.index, selected.value, 'expression')
	}

	render() {
		const changeConditionValue = data => {
			let value = undefined
			if (data && data.value) {
				value = data.value
			} else if (data && data.target && data.target.value) {
				value = data.target.value
			}

			this.props.changeCondition(this.props.index, value, 'value')
		}

		const changeRangeStartValue = data => {
			return changeConditionValue({
				value: {
					...this.props.condition.value,
					start: data.value ? data.value : data.target.value
				}
			})
		}

		const changeRangeEndValue = data => {
			return changeConditionValue({
				value: {
					...this.props.condition.value,
					end: data.value ? data.value : data.target.value
				}
			})
		}

    const renderConditions = condition => {
      switch (condition.expression) {
        case 'range':
          return (
            <div>
              <div className="col-md-6" style={{ 'paddingLeft':0 }}>
								{renderCondition(condition.value.start, changeRangeStartValue, 'Start')}
              </div>
              <div className="col-md-6" style={{ 'paddingRight':0 }}>
                {renderCondition(condition.value.end, changeRangeEndValue, 'End')}
              </div>
            </div>
          )
        default:
          return renderCondition(condition.value, changeConditionValue)
      }
    }

		const renderCondition = (value, onChangeValue, label) => {
			switch (this.props.condition.type) {
				case 'dimensions':
					return (
						<Dimensions
              changeConditionValue={onChangeValue}
							value={value}
							label={label}
						/>
					)
				case 'shipping-carrier':
					return (
						<ShippingCarrierService
							carrierServices={this.props.carrierServices}
              changeConditionValue={onChangeValue}
							value={value}
							label={label}
						/>
					)
				case 'weight':
					return (
						<Weight
              changeConditionValue={onChangeValue}
							value={value}
							label={label}
						/>
					)
				case 'country':
					return (
						<Country
              changeConditionValue={onChangeValue}
							value={value}
							label={label}
						/>
					)
				case 'postal-code':
					return (
						<PostalCode
              changeConditionValue={onChangeValue}
							value={value}
							label={label}
						/>
					)
				case 'tariff-category':
					return (
						<TariffCategory
							changeConditionValue={onChangeValue}
							value={value}
							label={label}
						/>
					)
				case 'sortation-type':
					return (
						<SortType
							changeConditionValue={onChangeValue}
							value={value}
							label={label}
						/>
					)
				case 'shipping-box':
					return (
						<ShippingBox
							changeConditionValue={onChangeValue}
							value={value}
							label={label}
							shippingBoxes={this.props.shippingBoxes}
						/>
					)
				case 'carrier-service-options':
					return (
						<CarrierServiceOptions
							changeConditionValue={onChangeValue}
							value={value}
							label={label}
							selectedCarrierService={this.props.selectedCarrierService}
							carrierServices={this.props.carrierServices}
						/>
					)
				default:
					return null
			}
		}

		return (
      <div>
        <div className="col-md-3">
          <Select
            value={this.props.condition.expression}
            options={allExpressionOptions[this.props.condition.type]}
            onChange={this.changeConditionExpression}
            clearable={false}
            searchable={false}
          />
        </div>
        <div className="col-md-9">
          {renderConditions(this.props.condition)}
        </div>
      </div>
		)
	}
}
