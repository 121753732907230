import * as actions from '../actions/ruleSetHistory'
import { INIT_FETCH_LIMIT } from '../actions/ruleSetHistory'

export default (
	state = {
		history: [],
		isFetching: false,
		show: false,
		limit: INIT_FETCH_LIMIT
	},
	action
) => {
	switch (action.type) {
		case actions.FETCH_RULESET_HISTORY_REQUEST:
			return { ...state, isFetching: true }
		case actions.FETCH_RULESET_HISTORY_SUCCESS:
			return {
				...state,
				history: action.history,
				isFetching: false
			}
		case actions.FETCH_RULESET_HISTORY_FAILURE:
			return {
				...state,
				isFetching: false,
				error: action.error
			}
		case actions.SHOW_RULE_SET_HISTORY:
			return {
				...state,
				ruleSet: action.ruleSet,
				show: true
			}
		case actions.FETCH_MORE_HISTORY_ROWS:
			return {
				...state,
				limit: action.limit,
				prevSize: action.prevSize
			}
		case actions.CLOSE_RULE_SET_HISTORY:
			return {
				...state,
				show: false,
				limit: INIT_FETCH_LIMIT,
				history: [],
				prevSize: 0
			}
		default:
			return state
	}
}
