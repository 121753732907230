// Removes various stateful properties that descibe scheduled changes e.g. isRemoved scheduled for removal
export const normalizeForBackend = data => {
	const result = {
		...data,
		rules: data.rules
			.map(rule => {
				if (rule.isRemoved) {
					return null
				}
				// White-list instead of black-list unwanted attributes
				return {
					conditions: rule.conditions,
					sortInformation: rule.sortInformation
				}
			})
			// Removes rules scheduled for removal
			.filter(rule => rule)
	}

	delete result.state
	return result
}
