import React, { Component } from 'react'

export class DropDownButton extends Component {
	constructor(props) {
		super(props)
		this.toggle = this.toggle.bind(this)
		this.state = {
			isOpen: false
		}
	}

	toggle() {
		this.setState({
			isOpen: !this.state.isOpen
		})
	}

	render() {
		return (
			<div
				className={this.state.isOpen ? 'dropdown open' : 'dropdown'}
				onClick={this.toggle}
			>
				<button
					className="btn-outline-primary btn"
					type="button"
					data-toggle="dropdown"
					aria-haspopup="true"
					aria-expanded="true"
				>
					<span className="select-arrow-zone">
						<i className="fa fa-plus" aria-hidden="true" />
						{this.props.title}
					</span>
				</button>
				{this.props.children ? (
					<ul className="dropdown-menu">{this.props.children}</ul>
				) : (
					undefined
				)}
			</div>
		)
	}
}
