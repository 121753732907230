import { ruleSetHistory } from '../selectors/selectors'
import { sortManApiClient } from '../common/api/sortMan'

export const FETCH_RULESET_HISTORY_REQUEST = 'FETCH_RULESET_HISTORY_REQUEST'
const requestRuleSetHistory = () => ({
	type: FETCH_RULESET_HISTORY_REQUEST
})

export const FETCH_RULESET_HISTORY_SUCCESS = 'FETCH_RULESET_HISTORY_SUCCESS'
const receiveRuleSetHistory = ruleSetHistory => ({
	type: FETCH_RULESET_HISTORY_SUCCESS,
	history: ruleSetHistory
})

export const FETCH_RULESET_HISTORY_FAILURE = 'FETCH_RULESET_HISTORY_FAILURE'
const failReceiveRuleSetHistory = (error) => ({
	type: FETCH_RULESET_HISTORY_FAILURE,
	error: {
		...error,
		reason: FETCH_RULESET_HISTORY_FAILURE
	}
})
export const INIT_FETCH_LIMIT = 15
const ruleSetHistoryUrl = (locationId, ruleSetId, offset, limit) =>
	`/api/v0/fulfillment-locations/${locationId}/rule-sets/${ruleSetId}/history?offset=${offset}&limit=${limit}`

export const FETCH_MORE_HISTORY_ROWS = 'FETCH_MORE_HISTORY_ROWS'
const fetchMoreRows = (locationId, ruleSetId, increaseLimit) => (
	dispatch,
	getState
) => {
	if (!locationId || !ruleSetId) {
		dispatch(failReceiveRuleSetHistory('Invalid location or ruleset!', null))
		return
	}
	const state = getState()
	const limit = state.history.limit + increaseLimit
	dispatch({
		type: FETCH_MORE_HISTORY_ROWS,
		limit,
		prevSize: ruleSetHistory.history(state).length
	})
	dispatch(fetchRuleSetHistory(locationId, ruleSetId, 0, limit)) //re-fetching all rows to handle the case when history changes
}

const fetchRuleSetHistory = (locationId, ruleSetId, offset, limit) => async (
	dispatch,
	getState
) => {
	if (!locationId || !ruleSetId) {
		dispatch(failReceiveRuleSetHistory('Invalid location or ruleset!', null))
		return
	}

	const state = getState()

	if (ruleSetHistory.isFetching(state)) {
		return null
	}
	dispatch(requestRuleSetHistory())

	let response
	try {
		response = await sortManApiClient.get(
			ruleSetHistoryUrl(locationId, ruleSetId, offset, limit)
		)
		dispatch(receiveRuleSetHistory(response.data))
	} catch (error) {
		dispatch(failReceiveRuleSetHistory(error))
	}
}

export const SHOW_RULE_SET_HISTORY = 'SHOW_RULE_SET_HISTORY'
const showRuleSetHistory = ruleSet => ({
	type: SHOW_RULE_SET_HISTORY,
	ruleSet
})

export const CLOSE_RULE_SET_HISTORY = 'CLOSE_RULE_SET_HISTORY'
const closeRuleSetHistory = () => ({
	type: CLOSE_RULE_SET_HISTORY
})

export {
	fetchRuleSetHistory,
	showRuleSetHistory,
	closeRuleSetHistory,
	fetchMoreRows
}
