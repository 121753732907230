export const isFetching = state => state.locations.isFetching
export const locations = state => state.locations.locations
export const isSuccessfullyLoaded = state =>
	state.locations.isSuccessfullyLoaded
export const formatLocationsForSelect = locations =>
	locations.map(location => ({
		value: location.fulfillmentLocationId,
		label: location.name
	}))

export const hasFailedGettingLocation = state => state.locations.getLocationFailed

const currentLocationId = state => state.locations.currentLocationId

export const currentLocation = state =>
	locations(state)
		? locations(state).find(
				location => location.fulfillmentLocationId === currentLocationId(state)
			)
		: null

export const locationInformation = state => state.locations.locationInformation
