import { Component } from 'react'

export class CarrierServiceOptions extends Component {
	render() {
    if(!this.props.value) {
      return
    } else if(!this.props.selectedCarrierService || !this.props.carrierServices || Object.keys(this.props.carrierServices).length < 1){
      return this.props.value
    } else {
      return this.props.carrierServices[this.props.selectedCarrierService].capabilities.find(capability => capability.key === this.props.value).name
    }
	}
}
