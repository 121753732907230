import { upperFirst, identity } from 'lodash'

// Monday used as LSB, Sunday as MSB
const DAYS_SLOTS = {
	monday: 1, // 0000001
	tuesday: 2, // 0000010
	wednesday: 4, // 0000100
	thursday: 8, // 0001000
	friday: 16, // 0010000
	saturday: 32, // 0100000
	sunday: 64 // 1000000
}
const SLOTS_WEEKDAYS =
	DAYS_SLOTS.monday |
	DAYS_SLOTS.tuesday |
	DAYS_SLOTS.wednesday |
	DAYS_SLOTS.thursday |
	DAYS_SLOTS.friday
const SLOTS_WEEKEND = DAYS_SLOTS.saturday | DAYS_SLOTS.sunday
const SLOTS_ALL_DAYS = SLOTS_WEEKDAYS | SLOTS_WEEKEND

const DAYS_ORDER = {
	monday: 0,
	tuesday: 1,
	wednesday: 2,
	thursday: 3,
	friday: 4,
	saturday: 5,
	sunday: 6
}
const DAYS = [
	'monday',
	'tuesday',
	'wednesday',
	'thursday',
	'friday',
	'saturday',
	'sunday'
]

// In case all days in Monday-Friday / Monday-Sunday range have a pickup scheduled at the same time reduce them to "weekdays" / "every day" respectively
export const reduceSchedule = days => {
	const timeSlots = {}

	// Categorize all time slots for days
	days.forEach(day => {
		day.times.forEach(slot => {
			if (!timeSlots[slot.time]) {
				timeSlots[slot.time] = 0
			}
			timeSlots[slot.time] |= DAYS_SLOTS[day.name]
		})
	})

	const allDays = []
	const weekDays = []
	const remainingDays = []

	Object.keys(timeSlots).forEach(slot => {
		let remainder = timeSlots[slot]
		if (timeSlots[slot] === SLOTS_ALL_DAYS) {
			allDays.push(slot)
			// Nothing else to do
			return
		} else if (timeSlots[slot] === SLOTS_WEEKDAYS) {
			weekDays.push(slot)
			remainder -= SLOTS_WEEKDAYS
		}

		if (remainder > 0) {
			DAYS.forEach(day => {
				if ((remainder & DAYS_SLOTS[day]) !== 0) {
					if (!remainingDays[DAYS_ORDER[day]]) {
						remainingDays[DAYS_ORDER[day]] = {
							name: upperFirst(day),
							times: []
						}
					}
					remainingDays[DAYS_ORDER[day]].times.push(slot)
				}
			})
		}
	})

	return {
		allDays,
		weekDays,
		remainingDays: remainingDays.filter(identity) // If schedule is missing for a day(s) sparse array may be created
	}
}

export const sortScheduleDays = weeklySchedule => {
	return Object.keys(weeklySchedule)
		.sort((a, b) => DAYS_ORDER[a] > DAYS_ORDER[b])
		.map(day => ({ name: day, times: weeklySchedule[day] }))
}
