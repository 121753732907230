import React, { Component } from 'react'
import { Modal } from '@cimpress/react-components'
import { RuleForm } from './RuleForm'

export default class EditRule extends Component {
	constructor() {
		super()
		this.handleConfirm = this.handleConfirm.bind(this)
		this.newRule = false
	}

	handleConfirm() {
		this.props.confirmEditingRule(
			this.props.editedRule.index,
			this.props.editedRule.ruleSetId,
			this.props.editedRule.data
		)

		if (this.newRule) {
			this.props.scrollToBottomRule.current.scrollIntoView({ behavior: 'smooth' })
			this.newRule = false
		}
	}

  validateRule (editedRule) {
    if (!editedRule) {
      return false
    }

		const conditionTypesAllowedOnlyOnce = [
			'shipping-carrier',
			'sortation-type',
			'postal-code',
			'shipping-box',
			'tariff-category'
		]

    const invalidConditions = editedRule.data.conditions.filter((condition) => {
      return !condition.value
		})

		const duplicateConditionsFound = conditionTypesAllowedOnlyOnce.find(type =>
			editedRule.data.conditions.filter(condition => condition.type === type).length > 1)

    const invalidSortInfo = editedRule.data.sortInformation.filter((sortInfo) => {
      return Object.keys(sortInfo.data).filter(key => {
        let [ attribute ] = this.props.sortStrategies.strategies
          .map(strategy => strategy.attributes.find(attribute => attribute.name === key))
          .filter(attr => attr)

        return (sortInfo.data[key] === '') || (sortInfo.data[key] === undefined) || (attribute && attribute.maxLength && sortInfo.data[key].length > attribute.maxLength)
      }).length > 0
    })
    return invalidConditions.length === 0 && invalidSortInfo.length === 0 && !duplicateConditionsFound
  }

	render() {
		const validRule = this.validateRule(this.props.editedRule)
		if (this.props.editedRule && this.props.editedRule.index === null) {
			this.newRule = true
		}
		return this.props.editedRule !== null ? (
			<Modal
				show={true}
				title={
					this.props.editedRule.index === null ? 'Adding rule' : 'Changing rule'
				}
				closeButton={true}
				bsSize="lg"
				onRequestHide={this.props.cancelEditingRule}
				footer={
					<div className="row">
						<div className="col-md-3 pull-left">
							<button
								className="btn btn-outline-secondary"
								onClick={this.props.cancelEditingRule}
							>
								<i className="fa fa-ban" aria-hidden="true" /> Discard changes
							</button>
						</div>
						<div className="col-md-3 pull-right">
							<button
								id='handleConfirm'
								className="btn btn-primary text-right"
								onClick={this.handleConfirm}
                disabled={!validRule}
							>
								<i className="fa fa-save" aria-hidden="true" /> Confirm changes
							</button>
						</div>
					</div>
				}
			>
				<RuleForm
					sortStrategies={this.props.sortStrategies}
					rule={this.props.editedRule.data}
					carrierServices={this.props.carrierServices}
					addCondition={this.props.addCondition}
					changeCondition={this.props.changeCondition}
					removeCondition={this.props.removeCondition}
					sortInfoChange={this.props.sortInfoChange}
					addSortInfo={this.props.addSortInfo}
					removeSortInfo={this.props.removeSortInfo}
					shippingBoxes={this.props.shippingBoxes}
				/>
			</Modal>
		) : null
	}
}
