import React, { Component } from 'react'

export class PostalCode extends Component {
	render() {
    if (this.props.value.includes(';') || this.props.value.includes(':')) {
      return(
        <span className="condition">
          <span className="tag oval">multiple</span>
        </span>
      )
    } else {
      return this.props.value
    }
	}
}
