import { createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import reducers from '../reducers/index'
import createHistory from 'history/createBrowserHistory'
import { routerMiddleware } from 'react-router-redux'
import { composeWithDevTools } from 'redux-devtools-extension'

export const history = createHistory()

export default preloadedState => {
	return createStore(
		reducers,
		preloadedState,
		composeWithDevTools(applyMiddleware(thunk, routerMiddleware(history)))
	)
}
