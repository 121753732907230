import React, { Component } from 'react'
import { Select, TextField } from '@cimpress/react-components'

export const expressionOptions = [
	{ value: 'eq', label: '=' }
]

export class ShippingCarrierService extends Component {
  render() {
    const invalidInput = (this.props.value === '');
    const valueOptions = value =>
      this.props.carrierServices
        ? Object.keys(this.props.carrierServices).map(key => ({
          value: key,
          label: this.props.carrierServices[key].hasSimilarName
            ? `${this.props.carrierServices[key].name} (${key})`
            : this.props.carrierServices[key].name
        }))
        : [{ value, label: value }]

    return (
      <div>
        {Object.keys(this.props.carrierServices).length > 0 ? (
          <Select
            label={invalidInput ? 'Please select a value for this field.' : ''}
            name="shipping-carrier-service"
            value={this.props.value}
            options={valueOptions(this.props.value)}
            onChange={this.props.changeConditionValue}
						clearable={false}
          />
        ) : (
          <TextField
            name="recs"
            label="recs"
            type="text"
            value={this.props.value}
            onChange={this.props.changeConditionValue}
            id={`shipping-carrier-service-condition-value-${this.props.index}`}
          />
        )}
      </div>
    )
  }
}
