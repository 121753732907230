import { pickupSchedules } from '../selectors/selectors'
import { sapiClient } from '../common/api/sapiClient'

export const FETCH_PICKUP_SCHEDULE_REQUEST = 'FETCH_PICKUP_SCHEDULE_REQUEST'
export const FETCH_PICKUP_SCHEDULE_SUCCESS = 'FETCH_PICKUP_SCHEDULE_SUCCESS'
export const FETCH_PICKUP_SCHEDULE_FAILURE = 'FETCH_PICKUP_SCHEDULE_FAILURE'

const pickupScheduleApiClient = sapiClient

const requestPickupSchedule = () => ({
	type: FETCH_PICKUP_SCHEDULE_REQUEST
})

const receivePickupSchedule = pickupSchedule => ({
	type: FETCH_PICKUP_SCHEDULE_SUCCESS,
	pickupSchedules: pickupSchedule
})

const failReceivePickupSchedule = (error) => ({
	type: FETCH_PICKUP_SCHEDULE_FAILURE,
	error: {
		...error,
		reason: FETCH_PICKUP_SCHEDULE_FAILURE
	}
})

const fetchPickupSchedules = () => async (dispatch, getState) => {
	const state = getState()

	if (pickupSchedules.isFetching(state)) {
		return null
	}
	dispatch(requestPickupSchedule())

	let response

	try {
		let fetchMoreSchedules = true
		let start = 1
		let schedules = []
		const increment = 50
		while (fetchMoreSchedules) {
			response = await pickupScheduleApiClient.get(
				`/v3/schedules?start=${start}&limit=${increment}`
			)
			if (!response.data) {
				fetchMoreSchedules = false
			} else {
				fetchMoreSchedules = response.data.schedules.length === increment
				schedules.push(...response.data.schedules)
				start += increment
			}
		}
		dispatch(receivePickupSchedule(schedules))
	} catch (error) {
		dispatch(
			failReceivePickupSchedule(error)
		)
	}
}

export { fetchPickupSchedules }
