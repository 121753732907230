import React, { Component } from 'react'
import { formatSchedule } from '../../../common/helpers/rule'
import { FETCH_PICKUP_SCHEDULE_FAILURE } from '../../../actions/pickupSchedule'

export class PickupSchedule extends Component {
	render() {
		const pickupScheduleById = (pickupSchedules, id) =>
			pickupSchedules
				? pickupSchedules.find(pickupSchedule => pickupSchedule.id === id)
				: undefined

		const schedule = pickupScheduleById(
			this.props.pickupSchedules,
			this.props.value
		)
		if (
			this.props.errors &&
			this.props.errors.find(
				err => err.reason === FETCH_PICKUP_SCHEDULE_FAILURE
			)
		) {
			return <code className="code">{this.props.value}</code>
		}
		if (!schedule) {
			return (
				<div>
					<i
						className="fa fa-warning"
						style={{ color: 'orange' }}
						aria-hidden="true"
					/>{' '}
					The pick up schedule can't be found.
				</div>
			)
		}
		const formatted = formatSchedule(schedule)
		return formatted.length !== 1 ? (
			<ul>
				{formatted.map((day, index) => (
					<li key={index}>
						{day.name}: {day.times.join(', ')}
					</li>
				))}
			</ul>
		) : (
			<span>
				{formatted[0].name}: {formatted[0].times.join(', ')}
			</span>
		)
	}
}
