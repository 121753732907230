import React, { Component } from 'react'
import { Link } from 'react-router-dom'

export default class FourOhFour extends Component {
	render() {
		return (
			<div>
				404, Sorry! We could find the page you requested.
				<Link to="/"> Go to the homepage</Link>.
			</div>
		)
	}
}
