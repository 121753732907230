import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Snackbar } from '../../common/components/Snackbar'
import { FETCH_RULESETS_FAILURE } from '../../actions/ruleSets'
import { FETCH_CARRIER_SERVICES_FAILURE } from '../../actions/capi'
import { FETCH_PICKUP_SCHEDULE_FAILURE } from '../../actions/pickupSchedule'
import { FETCH_SORT_STRATEGIES_FAILURE } from '../../actions/sortStrategies'
import { ERROR_GETTING_LOCATION } from '../../actions/locations'
import { FETCH_SHIPPING_BOXES_FAILURE } from '../../actions/boxman'

export class ErrorNotify extends Component {
	render() {
		const displayMessage = err => {
			//FIXME: find a better way how to compose error messages, consider localization?
			const email = 'ShipSorterSupport@cimpress.com'
			const sapi = 'SAPI'
			const sortman = 'SortMan'
			switch (err.reason) {
				case FETCH_PICKUP_SCHEDULE_FAILURE:
					if (err.statusCode === 401 || err.statusCode === 403) {
						return `You don't have permissions in SAPI to see Pickup Schedules, please contact ${email}.`
					} else {
						return `Error while loading Pickup Schedules, please contact ${email}.`
					}
				case FETCH_CARRIER_SERVICES_FAILURE:
					if (err.statusCode === 401 || err.statusCode === 403) {
						return `You don't have permissions in ${sapi} to see Carrier Services, please contact ${email}.`
					} else {
						return `Error while loading Carrier Services, please contact ${email}.`
					}
				case FETCH_RULESETS_FAILURE:
					if (err.statusCode === 401 || err.statusCode === 403) {
						return `You don't have permissions in ${sortman} to see Rules, please contact ${email}.`
					} else {
						return `Error while loading Rules, please contact ${email}.`
					}
				case FETCH_SORT_STRATEGIES_FAILURE:
					if (err.statusCode === 401 || err.statusCode === 403) {
						return `You don't have permissions in ${sortman} to see Sort Strategies, please contact ${email}.`
					} else {
						return `Error while loading Sort Strategies, please contact ${email}.`
					}
				case ERROR_GETTING_LOCATION:
					return `Error getting logistics location information. Units will default to kg and cm, please contact ${email}`
				case FETCH_SHIPPING_BOXES_FAILURE:
					return `Error while attempting to retrieve shipping boxes from Boxman, please contact ${email}`
				default:
					return `Unspecified API error, please contact ${email}.`
			}
		}
		const displayErrors = this.props.errors.map((err, index) => (
			<Snackbar
				key={index}
				level={
					err.statusCode > 399 && err.statusCode < 500 ? 'warning' : 'danger'
				}
				position={index * 100}
			>
				{displayMessage(err)}
			</Snackbar>
		))
		return <div>{displayErrors}</div>
	}
}

ErrorNotify.propTypes = {
	errors: PropTypes.array.isRequired
}
