import React, { Component } from 'react'
import { Select, TextField } from '@cimpress/react-components'

export const expressionOptions = [
	{ value: 'eq', label: '=' }
]

export class ShippingBox extends Component {
  render() {
    return (
      <div>
      { this.props.shippingBoxes.length > 0 ?
        (
          <Select
          label="Please select a value for this field."
          name="shipping-box"
          value={this.props.value}
          options={this.props.shippingBoxes}
          onChange={this.props.changeConditionValue}
          id={`shipping-box-${this.props.index}`}
          clearable={false}
        />
        ) : (
          <TextField
            name="shippingBox"
            label="Box ID"
            type="text"
            value={this.props.value}
            onChange={this.props.changeConditionValue}
            id={`shipping-box-${this.props.index}`}
          />
        )
       }
       </div>
    )
  }
}
