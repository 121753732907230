import React, { Component } from 'react'
import { SORT_INFO_NOT_SET } from '../../common/helpers/rule'

export class SortInformation extends Component {
	render() {
		return this.props.valueSet ? (
			this.props.sortInformation
		) : (
			<span className="tag oval">{SORT_INFO_NOT_SET}</span>
		)
	}
}
