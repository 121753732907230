import _ from 'lodash'

export const editRule = (state, action) => {
	const ruleSet = state.ruleSets.find(ruleSet => ruleSet.uuid === action.uuid)
	// Unexpected, incorrect ID
	if (!ruleSet) {
		return state
	}
	const rule = ruleSet.rules.find(rule => rule.index === action.index)
	if (!rule) {
		return state
	}
	return {
		...state,
		editedRule: {
			index: action.index,
			ruleSetId: action.uuid,
			data: _.cloneDeep(rule)
		}
	}
}

export const confirmEditingRule = (state, action) => {
	let wasCommitted = false
	return {
		...state,
		ruleSets: state.ruleSets.map(ruleSet => {
			if (ruleSet.uuid === action.uuid) {
				let rules
				if (action.index !== null) {
					rules = ruleSet.rules.map(rule => {
						if (rule.index === action.index) {
							wasCommitted = true
							return { ...action.data, isChanged: true }
						}
						return rule
					})
				} else {
					wasCommitted = true
					rules = [
						...ruleSet.rules,
						{
							...action.data,
							isAdded: true,
							isVisible: true,
							index: ruleSet.rules.length
						}
					]
				}
				return { ...ruleSet, rules }
			}
			return ruleSet
		}),
		// Do not throw away data if index was not matched
		editedRule: wasCommitted ? null : state.editedRule
	}
}
