import { boxman } from '../selectors/selectors'
import { boxmanClient } from '../common/api/boxman'

export const FETCH_SHIPPING_BOXES_REQUEST = 'FETCH_SHIPPING_BOXES_REQUEST'
export const FETCH_SHIPPING_BOXES_SUCCESS = 'FETCH_SHIPPING_BOXES_SUCCESS'
export const FETCH_SHIPPING_BOXES_FAILURE = 'FETCH_SHIPPING_BOXES_FAILURE'
export const FETCH_SHIPPING_BOXES_NO_CONFIG = 'FETCH_SHIPPING_BOXES_NO_CONFIG'

const requestShippingBoxes = () => ({
  type: FETCH_SHIPPING_BOXES_REQUEST
})
const receiveShippingBoxes = shippingBoxes => ({
  type: FETCH_SHIPPING_BOXES_SUCCESS,
  shippingBoxes
})

const receiveShippingBoxesNoConfig = () => ({
  type: FETCH_SHIPPING_BOXES_NO_CONFIG
})

const failReceiveShippingBoxes = (error) => ({
  type: FETCH_SHIPPING_BOXES_FAILURE,
  error: {
    ...error,
    reason: FETCH_SHIPPING_BOXES_FAILURE
  }
})

const fetchShippingBoxes = fulfillmentLocationId => async (dispatch, getState) => {
  const state = getState()

  if (boxman.isFetching(state)) {
    return null
  }
  dispatch(requestShippingBoxes())

  
  let shippingBoxes = []
  try {
    let {data} = await boxmanClient.get(`/api/v1/shippingBoxes?fulfillmentLocationId=${fulfillmentLocationId}`)
    if (data && Array.isArray(data)) {
      shippingBoxes = data.filter(shippingBox => {
        return shippingBox.Active === true
      })
      .filter(shippingBox => {
        return shippingBox.ShippingBoxId
      })
      .map( shippingBox => {
        let value = shippingBox.ShippingBoxId.toString()
        let label = shippingBox.Description || value
        return {value, label}
      })
      dispatch(
        receiveShippingBoxes(shippingBoxes.sort(comparer))
      )
    }
  } catch (error) {
    if (error.statusCode === 404) {
        dispatch(receiveShippingBoxesNoConfig())
    } 
    else {
      dispatch(failReceiveShippingBoxes(error))
    }
  }
}

function comparer (a, b) {
  if (a.label.toUpperCase() < b.label.toUpperCase()) {
    return -1
  }
  if (a.label.toUpperCase() > b.label.toUpperCase()) {
    return 1
  }
  return 0
}

export { fetchShippingBoxes }