import { capi } from '../selectors/selectors'
import { capiClient } from '../common/api/capi'

export const FETCH_CARRIER_SERVICES_REQUEST = 'FETCH_CARRIER_SERVICES_REQUEST'
export const FETCH_CARRIER_SERVICES_SUCCESS = 'FETCH_CARRIER_SERVICES_SUCCESS'
export const FETCH_CARRIER_SERVICES_FAILURE = 'FETCH_CARRIER_SERVICES_FAILURE'

const requestCarrierServices = () => ({
  type: FETCH_CARRIER_SERVICES_REQUEST
})

const receiveCarrierServices = carrierServices => ({
  type: FETCH_CARRIER_SERVICES_SUCCESS,
  carrierServices
})

const failReceiveCarrierServices = (error) => ({
  type: FETCH_CARRIER_SERVICES_FAILURE,
  error: {
    ...error,
    reason: FETCH_CARRIER_SERVICES_FAILURE
  }
})

const fetchCarrierServices = () => async (dispatch, getState) => {
  const state = getState()

  if (capi.isFetching(state)) {
    return null
  }
  dispatch(requestCarrierServices())

  let data
  let carrierServices = []
  let page = 1
  const pagesize = 100
  try {
    do {
      ({data} = await capiClient.get(`/api/v1/carrierServices?size=${pagesize}&page=${page}`))
      if(data.carrierServices) {
        carrierServices = carrierServices.concat(data.carrierServices)
      }
      page++
    } while (data.carrierServices && data.carrierServices.length === pagesize)

    dispatch(
      receiveCarrierServices((carrierServices || []).reduce((acc, {key, name, capabilities}) => ({...acc, [key]: {name, capabilities}}), {}))
    )
  } catch (error) {
    dispatch(
      failReceiveCarrierServices(error)
    )
  }
}

export { fetchCarrierServices }
