export const removeRule = (state, action) => {
	return {
		...state,
		ruleSets: state.ruleSets.map(ruleSet => {
			if (ruleSet.uuid !== action.uuid) {
				return ruleSet
			}

			return {
				...ruleSet,
				rules: ruleSet.rules.map(rule => {
					if (rule.index !== action.index) {
						return rule
					}
					return { ...rule, isRemoved: true }
				})
			}
		})
	}
}
