import React, { Component } from 'react'
import ReactDragListView from 'react-drag-listview'
import { Alert } from '@cimpress/react-components'
import { Snackbar } from '../../common/components/Snackbar'
import { getSortMan } from '../../config'
import { RULE_SET_REQUEST_STATE } from '../../actions/ruleSets'
import { Rule } from './Rule'
import SaveModal from './SaveModal'
import { VENLO } from '../../common/fulfillmentLocations'

import {
	fromCamelToHumanReadable,
	fromDashesToHumanReadable,
	purgeTechWords
} from '../../common/helpers/rule'

import { INIT_FETCH_LIMIT } from '../../actions/ruleSetHistory'

export default class RuleSetView extends Component {
	constructor(props) {
		super(props)
		this.onTabChange = this.onTabChange.bind(this)
		this.showHistory = this.showHistory.bind(this)
	}

	onTabChange(index) {
		return e => {
			e.preventDefault()
			this.props.viewRuleSet(index)
		}
	}

	showHistory(locationId, ruleSet) {
		this.props.fetchRuleSetHistory(
			locationId,
			ruleSet.uuid,
			0,
			INIT_FETCH_LIMIT
		)
		this.props.showRuleSetHistory(ruleSet)
	}

  convertConditionKeys(condition_key) {
    /* this is for display purposes only */
    if (condition_key === 'shipping-carrier') {
      return 'carrier-service'
    }
    return condition_key
  }

	render() {
		const collectConditionKeys = ruleSet =>
			[
				...ruleSet.rules.reduce(
					(conditionKeys, rule) =>
						rule.conditions.reduce(
							(conditionKeys, condition) => conditionKeys.add(condition.type),
							conditionKeys
						),
					new Set()
				)
			].sort()
		const collectSortInformationKeys = ruleSet =>
			[
				...ruleSet.rules.reduce(
					(sortInformationKeys, rule) =>
						rule.sortInformation.reduce(
							(sortInformationKeys, srtInf) =>
								Object.keys(srtInf.data).reduce(
									(sortInformationKeys, key) => sortInformationKeys.add(key),
									sortInformationKeys
								),
							sortInformationKeys
						),
					new Set()
				)
			].sort()
		const displaySortInformationKeys = ruleSet =>
			collectSortInformationKeys(ruleSet).map(key => (
				<th key={key}>{fromCamelToHumanReadable(key)}</th>
			))
		const displayConditionKeys = ruleSet =>
			collectConditionKeys(ruleSet).map((key, index, keys) => (
				<th
					key={key}
					className={keys.length === index + 1 ? 'table-separator' : {}}
				>
					{/* We currently have a conflict where both dashes and camelCase are used as separators */}
					{purgeTechWords(
						fromCamelToHumanReadable(fromDashesToHumanReadable(this.convertConditionKeys(key)))
					)}
				</th>
			))
		const conditionKeys = collectConditionKeys(this.props.ruleSet)
		const sortInformationKeys = collectSortInformationKeys(this.props.ruleSet)
		const displayButton = ruleSet => {
			switch (ruleSet.state) {
				case RULE_SET_REQUEST_STATE.inProgress:
					return (
						<span>
							<i className="fa fa-spinner fa-spin fa-fw" aria-hidden="true" />
							Saving your changes
							{this.props.locationId === VENLO ? ' and Transmitting' : ''}
						</span>
					)
				default:
					return (
						<span>
							<i className="fa fa-floppy-o" aria-hidden="true" /> Save changes
							{this.props.locationId === VENLO ? ' and Transmit' : ''}
						</span>
					)
			}
		}
		const displaySnackbar = ruleSet => {
			switch (ruleSet.state) {
				case RULE_SET_REQUEST_STATE.success:
					return (
						<Snackbar
							level="success"
							timeout={3 * 1000}
							onCloseCallback={() =>
								this.props.saveRuleSetSaved(this.props.ruleSet.uuid)
							}
						>
							Rule set "{ruleSet.name}" was saved successfully
						</Snackbar>
					)
				case RULE_SET_REQUEST_STATE.failure:
					// TODO: Determine type of error based on status code and either instruct the user to re-try or contact support
					return (
						<Snackbar
							level="danger"
							onCloseCallback={() =>
								this.props.saveRuleSetSaved(this.props.ruleSet.uuid)
							}
						>
							Rule set "{ruleSet.name}" could not be saved, please try again
						</Snackbar>
					)
				default:
					return undefined
			}
		}

		const dragProps = {
			onDragEnd: (fromIndex, toIndex) => {
				this.props.reorderRules(this.props.ruleSet.uuid, {
					source: { index: fromIndex },
					destination: { index: toIndex }
				})
			},
			handleSelector: '.drag-handle',
			nodeSelector: 'tr.draggable'
		}

		return (
			<div>
				<div
					className="btn-group btn-bottom-margin"
					role="group"
					aria-label="Rule Set group"
				>
					<button
						className="btn btn-default"
						onClick={this.props.addRule.bind(null, this.props.ruleSet.uuid)}
					>
						<i className="fa fa-plus" /> Add new rule
					</button>
				</div>
				<div
					className="btn-group btn-bottom-margin pull-right"
					role="group"
					aria-label="Rule Set group"
				>
					<button
						className="btn btn-default"
						onClick={() =>
							this.showHistory(this.props.locationId, this.props.ruleSet)
						}
					>
						<i className="fa fa-book" /> Show history
					</button>
				</div>

				{this.props.ruleSet.rules.length > 0 ? (
					<ReactDragListView {...dragProps}>
						<table className="table table-hover">
							<thead>
								<tr>
									<th>{/* Intentionally empty */}</th>
									<th
										colSpan={conditionKeys.length}
										className="table-separator"
									>
										<h5>Rules</h5>
									</th>
									{/* +1 because of empty th in the line bellow */}
									<th colSpan={sortInformationKeys.length}>
										<h5>
											{this.props.ruleSet.name
												? this.props.ruleSet.name
												: `Rule Set ${this.props.index + 1}`}
										</h5>
									</th>
									<th colSpan={2}>
										<button
											className="btn btn-link rule-set-edit"
											onClick={this.props.changeRuleSetConfiguration.bind(
												null,
												this.props.ruleSet.uuid,
												{
													name: this.props.ruleSet.name,
													fulfillmentLocationId: this.props.locationId
												}
											)}
										>
											<i className="fa fa-cogs" />
										</button>
									</th>
								</tr>
								<tr>
									<th>{/* Intentionally empty */}</th>
									{displayConditionKeys(this.props.ruleSet)}
									{displaySortInformationKeys(this.props.ruleSet)}
									<th>
										{/* Intentionally empty, adding colspan to last <th> displayed dynamically would unnecessarily complicate the code */}
									</th>
								</tr>
							</thead>
							<tbody>
								{this.props.ruleSet.rules
									.filter(rule => rule.isVisible)
									.map(rule => (
										<Rule
											rule={rule}
											removeRule={this.props.removeRule}
											editRule={this.props.editRule}
											revertRule={this.props.revertRule}
											ruleSetId={this.props.ruleSet.uuid}
											carrierServices={this.props.carrierServices}
											shippingBoxes={this.props.shippingBoxes}
											pickupSchedules={this.props.pickupSchedules}
											conditionKeys={conditionKeys}
											sortInformationKeys={sortInformationKeys}
											key={rule.index}
											errors={this.props.errors}
										/>
									))}
							</tbody>
						</table>
					</ReactDragListView>
				) : (
					<Alert
						message={`There are no rules created for rule set "${
							this.props.ruleSet.name
						}" yet, click on "Add new rule" button to create one.`}
						type="info"
						dismissible={false}
					/>
				)}
				<div className="row rule-set-controls">
					<div className="col-md-3 pull-left">
						{getSortMan().disableSaving ? (
							<button className="btn btn-primary" disabled>
								<span>
									<i className="fa fa-floppy-o" aria-hidden="true" />Save
									changes
								</span>
							</button>
						) : (
							<button
								className="btn btn-primary"
								onClick={this.props.openSaveModalConfirmationDialog}
							>
								{displayButton(this.props.ruleSet)}
							</button>
						)}
						<div>{displaySnackbar(this.props.ruleSet)}</div>
						<SaveModal {...this.props} />
					</div>
					<div className="col-md-3 pull-right">
						<button
							className="btn btn-outline-secondary revert-all pull-right"
							onClick={this.props.revertRuleSet.bind(
								null,
								this.props.ruleSet.uuid
							)}
						>
							<i className="fa fa-ban" aria-hidden="true" /> Revert all changes
							in rule set
						</button>
					</div>
				</div>
			</div>
		)
	}
}
