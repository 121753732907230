import React, { Component } from 'react'
import { connect } from 'react-redux'
import { ruleSetHistory } from '../../selectors/selectors'
import {
	closeRuleSetHistory,
	fetchMoreRows,
	INIT_FETCH_LIMIT
} from '../../actions/ruleSetHistory'
import Spinner from '@cimpress/react-components/lib/shapes/Spinner'
import Moment from 'react-moment'
import { Modal } from '@cimpress/react-components'
import InfiniteScroll from 'react-infinite-scroll-component'

class History extends Component {
	constructor(props) {
		super(props)
		this.onNext = this.onNext.bind(this)
	}

	onNext() {
		this.props.fetchMoreRows(this.props.locationId, this.props.ruleSet.uuid, 10)
	}

	shouldComponentUpdate(nextProps, nextState) {
		return this.props.history !== nextProps.history
	}

	render() {
		const currentRevisionOnTop = ruleSetHistory => {
			const history = ruleSetHistory.slice()
			history.unshift(this.props.ruleSet)
			return history
		}
		return (
			<Modal
				show={this.props.show}
				title="History"
				footer={
					<div className="row">
						<div className="col-md-3 pull-right">
							<button
								className="btn btn-outline-secondary discard"
								onClick={this.props.closeRuleSetHistory.bind(null)}
							>
								<i className="fa fa-times" aria-hidden="true" />
								Close
							</button>
						</div>
					</div>
				}
				closeButton={true}
				bsSize="lg"
				onRequestHide={this.props.closeRuleSetHistory.bind(null)}
			>
				{this.props.isFetching ? (
					<Spinner size={36} />
				) : (
					<InfiniteScroll
						pullDownToRefresh
						pullDownToRefreshContent={
							<h3 className="text-center">
								<i className="fa fa-arrow-down" aria-hidden="true" />Pull down
								to refresh
							</h3>
						}
						releaseToRefreshContent={
							<h3 className="text-center">
								<i className="fa fa-arrow-up" aria-hidden="true" /> Release to
								refresh
							</h3>
						}
						next={this.onNext}
						refreshFunction={() => {}} //FIXME: not sure why this is mandatory
						hasMore={
							!(
								this.props.size === 0 ||
								INIT_FETCH_LIMIT > this.props.size ||
								this.props.size === this.props.prevSize
							)
						}
						height={450}
						scrollThreshold={0.75}
						pullDownToRefreshThreshold={100}
						loader={<Spinner className="text-center" size={24} />}
						endMessage={
							<p className="text-center">
								<b>All history was loaded</b>
							</p>
						}
					>
						<table className="table table-hover ">
							<thead>
								<tr>
									<th>Revision</th>
									<th>Name</th>
									<th>Email</th>
									<th>Modified</th>
									<th>Note</th>
								</tr>
							</thead>
							<tbody>
								{this.props.history && this.props.size > 0 ? (
									currentRevisionOnTop(this.props.history).map(
										(history, index) => (
											<tr key={index}>
												<td>
													{index === 0 ? (
														<span className="label label-primary">
															{history.revision}
														</span>
													) : (
														<span className="label label-default">
															{history.revision}
														</span>
													)}
												</td>
												<td>{history.savedBy.name || history.savedBy.sub}</td>
												<td>{history.savedBy.email}</td>
												<td>
													<Moment format="YYYY-MM-DD HH:mm:ss">
														{history.created}
													</Moment>
												</td>
												<td>{history.description}</td>
											</tr>
										)
									)
								) : this.props.ruleSet ? (
									<tr>
										<td>
											<span className="label label-primary">
												{this.props.ruleSet.revision}
											</span>
										</td>
										<td>
											{(this.props.ruleSet.savedBy && this.props.ruleSet.savedBy.name) ||
											(this.props.ruleSet.savedBy && this.props.ruleSet.savedBy.sub)}
										</td>
										<td>{this.props.ruleSet.savedBy && this.props.ruleSet.savedBy.email}</td>
										<td>
											<Moment format="YYYY-MM-DD HH:mm:ss">
												{this.props.ruleSet.created}
											</Moment>
										</td>
									</tr>
								) : (
									<tr colSpan={4}>
										<td>No history</td>
										<td>{/** intentionally empty **/}</td>
										<td>{/** intentionally empty **/}</td>
										<td>{/** intentionally empty **/}</td>
									</tr>
								)}
							</tbody>
						</table>
					</InfiniteScroll>
				)}
			</Modal>
		)
	}
}

export default connect(
	state => ({
		history: ruleSetHistory.history(state),
		isFetching: ruleSetHistory.isFetching(state),
		show: state.history.show,
		ruleSet: state.history.ruleSet,
		prevSize: state.history.prevSize,
		size: ruleSetHistory.size(state)
	}),
	{
		fetchMoreRows,
		closeRuleSetHistory
	}
)(History)
