import * as actions from '../actions/pickupSchedule'

export default (
	state = { pickupSchedules: [], isFetchingPickupSchedule: false },
	action
) => {
	switch (action.type) {
		case actions.FETCH_PICKUP_SCHEDULE_REQUEST:
			return { ...state, isFetchingPickupSchedule: true }
		case actions.FETCH_PICKUP_SCHEDULE_SUCCESS:
			return {
				...state,
				pickupSchedules: action.pickupSchedules,
				isFetchingPickupSchedule: false
			}
		case actions.FETCH_PICKUP_SCHEDULE_FAILURE:
			return {
				...state,
				isFetchingPickupSchedule: false,
				error: action.error
			}
		default:
			return state
	}
}
