import React, { Component } from 'react'
import { TextField } from '@cimpress/react-components'
import { Navtabs } from '../../common/components/Navtabs'
import RuleSetView from './RuleSetView'
import _ from 'lodash'

import './Table.css'
import './common.css'

export default class Table extends Component {
	constructor(props) {
		super(props)
		this.onInputChange = this.onInputChange.bind(this)
	}

	shouldComponentUpdate(nextProps, nextState) {
		return (
			this.props.carrierServices !== nextProps.carrierServices ||
			this.props.pickupSchedules !== nextProps.pickupSchedules ||
			this.props.ruleSets !== nextProps.ruleSets ||
			this.props.descriptions !== nextProps.descriptions ||
			this.props.openSaveModal !== nextProps.openSaveModal ||
			this.props.sortStrategies !== nextProps.sortStrategies ||
			!_.isEqual(this.props.errors, nextProps.errors)
		)
	}

	onInputChange(e) {
		this.props.filterRules(e.target.value)
	}

	render() {
		const ruleSets = this.props.ruleSets.map((ruleSet, index) => {
			const hasVisibleRules = ruleSet =>
				ruleSet.rules.find(rule => rule.isVisible)

			return (
				<RuleSetView
					{...this.props}
					ruleSet={ruleSet}
					isDisabled={!hasVisibleRules(ruleSet)}
					index={index}
					description={this.props.descriptions[ruleSet.uuid]}
				/>
			)
		})

		const ruleSetTabs = this.props.isFetchingRuleSets ? (
			<div />
		) : (
			this.props.ruleSets.map((ruleSet, index) => {
				return { title: ruleSet.name ? ruleSet.name : `Rule Set ${index + 1}` }
			})
		)

		return (
			<div className="rule-sets rule-sets-table">
				<div className="row filter">
					<div className="col-md-12">
						<TextField
							name="search"
							value={this.props.searchText}
							label={this.props.searchText === '' ? 'filter...' : ''}
							onChange={this.onInputChange}
						/>
					</div>
				</div>
				<Navtabs navigationTabs={ruleSetTabs} contentTabs={ruleSets} />
			</div>
		)
	}
}
