export const triggerRuleSetConfigurationChange = (state, action) => {
	const existingData = state.editedRuleSetConfiguration
		? state.editedRuleSetConfiguration.data
		: {}
	return {
		...state,
		editedRuleSetConfiguration: {
			fulfillmentLocationId: action.fulfillmentLocationId,
			ruleSetId: action.ruleSetId,
			data: { ...existingData, ...action.data }
		}
	}
}

export const discardRuleSetConfigurationChange = (state, action) => {
	return {
		...state,
		editedRuleSetConfiguration: null
	}
}

export const confirmRuleSetConfigurationChange = (state, action) => {
	let wasChangeCommitted = false
	if (action.ruleSetId) {
		return {
			...state,
			ruleSets: state.ruleSets.map(ruleSet => {
				if (ruleSet.uuid === action.ruleSetId) {
					wasChangeCommitted = true
					return { ...ruleSet, ...state.editedRuleSetConfiguration.data }
				}
				return ruleSet
			}),
			editedRuleSetConfiguration: wasChangeCommitted
				? null
				: state.editedRuleSetConfiguration
		}
	}

	state.ruleSets.push({
		...state.editedRuleSetConfiguration.data,
		active: true,
		rules: []
	})

	return {
		...state,
		editedRuleSetConfiguration: null
	}
}
