import React, { Component } from 'react'
import { TextField } from '@cimpress/react-components'

export const expressionOptions = [
	{ value: 'eq', label: '=' },
	{ value: 'range', label: 'Between' }
]

const postalCodeRangeMatcher = '^[A-Z|a-z|0-9|;|:]*$'
const postalCodeBetweenMatcher = '^[A-Z|a-z|0-9]*$'

export class PostalCode extends Component {
	constructor(props) {
		super(props)
		this.onChange = this.onChange.bind(this)
		this.validateRange = this.validateRange.bind(this)
		this.state = {valid: true}
	}

	onChange(event) {
		let value = event.target.value
		let matcher = postalCodeRangeMatcher
		if(this.props.label === 'Start' || this.props.label === 'End') {
			matcher = postalCodeBetweenMatcher
		}
		if(value.match(matcher)){
			this.props.changeConditionValue({value: value.toUpperCase()})
		}

		let ranges = value.split(';')
		if (ranges && ranges.length > 1) {
			if (ranges.every(this.validateRange)) {
				this.setState({valid: true})
			} else {
				this.setState({valid: false})
			}
		} else {
			this.setState({valid: true})
		}
	}

	validateRange(range) {
		let values = range.split(':')
		if(values.length !== 2){
			return false
		} else {
			return values[0] <= values[1] && values[0].length === values[1].length
		}
	}

	render() {
		return (
			<TextField
				name="number"
				label={this.props.label || 'postal code'}
				type="text"
				bsStyle={this.state.valid ? '' : 'error'}
				value={this.props.value}
				onChange={this.onChange}
				id={`postal-code-condition-value-${this.props.index}`}
			/>
		)
	}
}
