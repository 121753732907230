import React, { Component } from 'react'
import { VENLO } from '../../common/fulfillmentLocations'
import { Modal, TextField } from '@cimpress/react-components'

export default class SaveModal extends Component {
	constructor(props) {
		super(props)
		this.onDescriptionChange = this.onDescriptionChange.bind(this)
	}

	shouldComponentUpdate(nextProps, nextState) {
		return (
			this.props.openSaveModal !== nextProps.openSaveModal ||
			this.props.description !== nextProps.description
		)
	}

	onDescriptionChange(ruleSetId) {
		return e => {
			if (e) {
				this.props.changeDescription(ruleSetId, e.target.value)
			}
		}
	}

	saveChangesForRuleSet(ruleSetId) {
		this.props.saveRuleSet(this.props.locationId, ruleSetId)
		this.props.closeSaveModalConfirmationDialog()
	}

	render() {
		return (
			<Modal
				bsStyle={'info'}
				show={this.props.openSaveModal}
				onRequestHide={this.props.closeSaveModalConfirmationDialog}
				title="Confirm your changes"
				closeButton={true}
				footer={
					<div className="">
						<div className="col-md-3">
							<button
								className="btn btn-default"
								onClick={this.props.closeSaveModalConfirmationDialog}
							>
								<i className="fa fa-times" aria-hidden="true" />
								Close without saving
							</button>
						</div>
						<div className="col-md-6 pull-right">
							<button
								className="btn btn-primary"
								onClick={() =>
									this.saveChangesForRuleSet(this.props.ruleSet.uuid)
								}
							>
								<i className="fa fa-save" aria-hidden="true" /> Save changes{' '}
								{this.props.locationId === VENLO ? ' and transmit' : ''}
							</button>
						</div>
					</div>
				}
			>
				<p>
					Are you sure you want to save your changes to rule set{' '}
					<em>
						<strong>{this.props.ruleSet.name}</strong>
					</em>
					{this.props.locationId === VENLO
						? ' and transmit sort locations to BeSS'
						: ''}
					?
				</p>
				<TextField
					key={this.props.ruleSet.uuid + 'description'}
					id={this.props.ruleSet.uuid + 'description'}
					label={'Description of your change (optional)'}
					value={this.props.description}
					onChange={this.onDescriptionChange(this.props.ruleSet.uuid)}
				/>
			</Modal>
		)
	}
}
