import { upperFirst } from 'lodash'
import countryList from 'country-list'
import { reduceSchedule, sortScheduleDays } from './schedule'

export const purgeTechWords = colName => {
	const blacklist = ['Id']
	return colName
		.split(' ')
		.filter(e => !blacklist.includes(e))
		.join(' ')
}

export const fromDashesToHumanReadable = type =>
	type
		.split('-')
		.map(upperFirst)
		.join(' ')

export const fromCamelToHumanReadable = type =>
	type
		.match(/[A-Z]*[^A-Z]+/g)
		.map(upperFirst)
		.join(' ')

export const formatExpression = expression => {
	switch (expression) {
		case undefined:
		case 'eq':
			return ''
		case 'lt':
			return '<'
		case 'gt':
			return '>'
		case 'lte':
			return '≤'
		case 'gte':
			return '≥'
		case 'range':
			return 'between'
		default:
			return expression
	}
}

export const formatDimensions = dimensions => {
	let unit = dimensions.unit || 'cm'
	if (dimensions.x && dimensions.y && dimensions.z) {
		return `${dimensions.x} x ${dimensions.y} x ${dimensions.z} ${unit}`
	} else {
		return 	`${dimensions[0]} x ${dimensions[1]} x ${dimensions[2]} ${unit}`
	}
}

export const formatWeight = value => {
	let unit = value.unit || 'kg'

	if (value.number) {
		return `${value.number} ${unit}`
	} else {
		return `${value} ${unit}`
	}
}

export const formatCountryCode = countryCode => {
	if (!countryCode) {
		return countryCode
	}
	const codeList = countryList().getCodeList()
	return codeList[countryCode.toLowerCase()] || countryCode
}

// Sorts schedules in days based on a format human would expect
export const formatSchedule = schedule => {
	const sorted = sortScheduleDays(schedule.rule.weeklySchedule)
	const reduced = reduceSchedule(sorted)

	let formatted = []
	if (reduced.allDays.length) {
		formatted.push({
			name: 'Every day',
			times: reduced.allDays
		})
	}
	if (reduced.weekDays.length) {
		formatted.push({
			name: 'Weekdays',
			times: reduced.weekDays
		})
	}

	if (reduced.remainingDays.length) {
		formatted = formatted.concat(reduced.remainingDays)
	}

	return formatted
}

export const ANY_EXPRESSION = 'any'
export const SORT_INFO_NOT_SET = 'not set'
