import React, { Component } from 'react'
import { Select } from '@cimpress/react-components'

const FDA_CONDITION = 'Declared - US FDA'
const DECLARED_CONDITION = 'Declared - General'
const UNDECLARED_CONDITION = 'Undeclared'

export const expressionOptions = [
	{ value: 'eq', label: '=' }
]

export class TariffCategory extends Component {
	render() {
		const tariffCategories = [
			{ value: FDA_CONDITION, label: FDA_CONDITION },
			{ value: DECLARED_CONDITION, label: DECLARED_CONDITION },
			{ value: UNDECLARED_CONDITION, label: UNDECLARED_CONDITION }
		]

		return (
			<Select
				name="tc"
				value={this.props.value}
				options={tariffCategories}
				onChange={this.props.changeConditionValue}
				id={`tariff-category-text-${this.props.index}`}
			/>
		)
	}
}
