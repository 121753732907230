import { Component } from 'react'

export class ShippingBox extends Component {
	render() {
		// eslint-disable-next-line
		const selectedBox = this.props.shippingBoxes.find(shippingBox => {
			if (shippingBox.value === this.props.value) {
				return shippingBox
			}
		})

		return (selectedBox && selectedBox.label) ? selectedBox.label : this.props.value
	}
}