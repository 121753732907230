import auth from '../common/auth/auth'

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_ERROR = 'LOGIN_ERROR'
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'

const logInAction = () => async (dispatch) => {
	await auth.login()
		.then(() => {
			dispatch(createLogInEvent())
		})
		.catch(error => {
			dispatch(createLogInErrorEvent(error))
		})
}

const logOutAction = () => async dispatch => {
	await auth.logout('/', true)
	dispatch(createLogOutEvent())
}

const createLogInEvent = () => ({
	type: LOGIN_SUCCESS,
	profile: auth.getProfile()
})

const createLogInErrorEvent = error => ({
	type: LOGIN_ERROR,
	error: error
})

const createLogOutEvent = () => ({
	type: LOGOUT_SUCCESS
})

export { logInAction, logOutAction }
